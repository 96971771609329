import Vue from 'vue'
import Vuei18n from 'vue-i18n'

import es_MX from './es_MX'
import en_US from './en_US'

Vue.use(Vuei18n);

const messages = {
    es_MX,
    en_US
};

export default new Vuei18n({
    locale: 'es_MX',
    fallbackLocale: 'en_US',
    messages
});
