export default {
    name: 'app',
    data(){
        return {
            lang: null,
            scroll: false,
            showMenu: false,
            route: '',
        }
    },
    methods:{
        setLang: function(lang){
            this.$i18n.locale = lang;
            window.localStorage.setItem('lang', lang);
            this.lang = lang;
            this.showMenu = false;
        },
        handleScroll: function () {
            this.scroll = window.pageYOffset > 0;
        },
        contact: async function(){
            if (this.$route.path !== '/')
			    await this.$router.push('/');
            this.showMenu = false;
			this.$scrollTo('#contact');
		},
        products: async function(){
            this.showMenu = false;
            if (this.$route.hash === '#products')
			    this.$scrollTo('#products');
            else
                await this.$router.push('/#products');
		},
    },
    watch:{
        '$route' (t){
            if (t.path !== this.route){
                this.showMenu = false;
            }
            this.route = this.$route.path;
        }
    },
    mounted(){
        let lang = window.localStorage.getItem('lang');
        if(!lang || lang === 'null') lang = navigator.language.includes('es')? 'es_MX' : 'en_US';
        this.setLang(lang);
        window.onscroll = this.handleScroll;
        this.handleScroll();
        this.route = this.$route.path;
    }
}