import Vue from 'vue'
import App from './app'
import router from './router'
import i18n from '../i18n';
import VueScrollTo from 'vue-scrollto';

Vue.config.productionTip = false

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

new Vue({
  router,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')
