export default {
    header:{//listo
        links: {
            0: 'Home',
            1: 'About Us',
            2: 'Products',
            3: 'Services',
            4: 'Engineering',
            5: 'Blog',
            6: 'Contact',
        },
        lang:{
            spanish: 'SP',
            english: 'EN'
        }
    },
    home: { //listo (falta checar botones)
        hero: {//listo
            title: 'Providing solutions for the industry since 1981.',
            button: 'CONTACT US',
        },
        us: {//listo
            title: 'OUR COMPANY',
            body: `
                <p>We are a Mexican company with more than 40 years of experience, specialized in providing solutions in compressed air systems for the industry in general.</p>
                <p>Direct factory distributors of companies such as <span>"Sullair"</span>, <span>"Prevost"</span>, <span>"Lone Star"</span>, <span>"Holtec Gas System"</span> and <span>"Pricepump"</span>.</p>
                <p>We have the most complete warehouse in the country and the largest stock of original <span>"Sullair"</span> spare parts.</p>
            `,
            button: 'CONTACT US'
        },
        award: {
            title: 'We received the award:',
            description: {
                1: '"Best Latin American Distributor in Oil-Free Compressors"',
                2: 'at the 2023 Annual Awards Ceremony by Sullair.'
            }
        },
        products:{//listo
            title: 'PRODUCTS',
            cards: {
                0: 'Pumping Systems',
                1: 'Vacuum Pumps',
                2: 'Air Treatment Systems',
                3: 'Nitrogen Generators',
                4: 'Industrial Cleaning',
                5: 'Turbo Blowers',
                6: 'Compressed air systems',
                7: 'Compressed air distribution networks'
            }
        },
        services: {//listo
            title: 'SERVICES',
            subtitle: 'We offer courses, consulting services, distribution, rental and maintenance for compressed air systems.',
            icons:{
                0:{
                    title: 'Rental Fleet',
                    description: 'In order to provide support and aware of the importance of compressed air in their processes, we offer a huge rental equipment fleet.',
                },
                1: {
                    title: 'Maintenance',
                    description: 'We offer preventive and corrective maintenance services. Not only for “Sullair” systems, we can also offer these services for different compressor brands.'
                },
                2: {
                    title: 'Policies',
                    description: 'A policy is a preventive and safe plan designed to bring compressed air systems up to optimum efficiency, which leads to minimize shutdowns caused by major breakdowns, extending equipment lifetime, and even achieving a better energy consumption.'
                },
                3: {
                    title: 'Spare parts',
                    description: '100% Original spare parts, which come from the factory. We have stock in all our branches, reducing delivery times.',
                },
                4: {
                    title: 'MAQ (Monitoring Air Quality)',
                    description: 'MAQ (Monitoring Air Quality) is a monitoring service, in which a complete study of the generation and distribution process of the compressed air system is obtained.'
                },
                5: {
                    title: 'Energy Measurement',
                    description: 'Energy measurement is a report that details, by means of graphs, the energy consumption of your compressed air equipment in real time'
                },
                6: {
                    title: 'Courses',
                    description: 'Our courses provide theoretical and practical knowledge for the proper selection, operation, maintenance and inspection for compressors.'
                },
                7: {
                    title: 'Airend repair',
                    description: 'Our specialized technicians , fully equipped workshop  and unique tools are available to perform and exact diagnosis and maintenance for compressor units.'
                }
            }
        },
        support:{//listo (no hay check de los botones)
            title: 'Maintenance',
            body: `We offer preventive and corrective maintenance services. Not only for “Sullair” systems, we can also offer these services for different compressor brands.`,
            button: 'MORE',
        },
        blog:{//listo (no hay check de los botones)
            title: 'Blog',
            cardButton: 'MORE',
            button: 'MORE'
        },
        contact:{//listo
            title: 'CONTACT',
            form:{
                name: 'Name',
                email: 'Email address',
                phone: 'Phone number',
                message: 'Message',
                submit: 'SEND'
            },
            service:{
                title: '24/7 SERVICE',
                description: `
                    <p>Our trained staff is available 24 hours a day, 7 days a week, to assist you in case of any emergency at the following number: <a href="tel:+52 3334403133" title="Servicio 24/7">+52 (33) 3440-3133.</a></p>
                `
            }
        },
        offices:{//listo
            title: 'BRANCH OFFICES',
            body: `
                <p>Our facilities are located in Guadalajara, Monterrey, Hermosillo, Durango, Mexicali, Tijuana, Toluca, Puebla and Los Angeles.</p>
            `,
            cta: 'Write to us at:'
        },
    },
    aboutUs:{ //listo (falta checar el titulo de cerficaciones)
        about:{//listo
            title: 'ABOUT US',
            body: `
                <p>Together with our suppliers, we offer integral solutions to ensure that our customers' compressed air systems are at their optimum efficiency. We also have distribution networks for compressed air and complement our brochure with nitrogen generators, turbo blowers, industrial cleaning and thermal processes.</p>
            `,
            button: 'CONTACT US',
        },
        mission: {//listo
            0: {
                title: 'Mision',
                description: 'We provide integral compressed air solutions for the industry. Promoting the growth of clean energy, adding value, generating trust, confidence and being at the forefront of our processes; with highly trained personnel and committed to the philosophy of our shareholders.'
            },
            1: {
                title: 'Vision',
                description: 'Our 2025 vision is "to bring the compressed air systems of all our customers to their optimum efficiency while strengthening the structure of the company".'
            },
            2: {
                title: 'Values',
                description: 'Our values are Teamwork, Responsibility, Integrity, Passion, Proactivity, Loyalty and Quality.'
            }
        },
        history: { //listo
            title: 'HISTORY',
            description: 'Compresores Maqpower, a Mexican family business, began operations in 1981 and by 2020 had 9 branch offices and official distribution of 6 brands.',
            years: {
                0: "Compresores Maqpower, being a Mexican family business, began operations under the name of Compresores Perforadoras y Componentes Hidráulicos Maqpower, S.A.",
                1: "The exclusive distribution in the Pacific and northern Mexico is signed with SULLAIR® CORPORATION. In the same year, the company changed its name to Compresores Maqpower® S.A. de C.V.",
                2: "Maqpower opens in Hermosillo",
                3: "Maqpower opens in Tijuana.",
                4: "Succession of general director of the company and in the same year the Maqpower opens in Mexicali and Torreón.",
                5: "New offices in Guadalajara opens its door. in the City Industrial Park Park in Zapopan, Jalisco.",
                6: "Distribution of the Price Pump® brand of centrifugal and diaphragm pumps is obtained for all of Mexico.",
                7: "The company Compresores Multiflo S.A. de C.V. is positioned in the city of León, Guanajuato to serve the Bajío area, and signs distribution with Compresores Maqpower® S.A. de C.V.",
                8: "Maqpower Mexico receives distributorship of Sioux™",
                9: "The first agent in Mexico certified by the US Government’s Department of Energy as a Qualified AirMaster & Compressed Air Specialist, Maqpower enters the Centrifugal Compressor market in all of Mexico.",
                10: "Maqpower updates its image and logo.",
                11: "Maqpower opens in Toluca.",
                12: "Hitachi purchases SULLAIR and presence of both brands in Mexico which causes the plan for VISION 2025 Maqpower acquires distribution rights in Los Angeles Territory in California.",
                13: "Maqpower acquires new lines of product: Lone Star Turbo Industrial Blowers, Prevost with aluminum pipe and all connections up to the point of use, Holtec with PSA and membrane Nitrogen Generators.",
                14: "Maqpower opens in Durango.",
                15: "Maqpower celebrates 40 years of operations.",
            }
        },
        certifications: {
            title: "Our suppliers' certifications"
        },
    },
    products: {//listo
        contact: {
            button: 'CONTACT US',
        },
        notfound: {
            title: '404 NOT FOUND'
        },
        tabs: {
            description: 'Description',
            features: 'Characteristics'
        },
        types:{//listo
            title: 'PRODUCTS',
            categories: {
                0: { //Sistemas de bombeo
                    id: 'sistemas-de-bombeo',
                    title: 'Pumping Systems',
                    description: `
                        <p>Our “Price Pump” pumping systems had a great variety of centrifugal and diaphragm pumps, which are customized according to your industry needs.</p>
                    `,
                    0: {//Bombas de diafragma
                        title:'Diaphragm Pumps',
                        0: {
                            id: 'bombas-de-diafragma',
                            title: 'Diaphragm Pumps',
                            description: `
                                <p>The “Price Pump” diaphragm pumps consist of customized equipments that allow the movement of chemical liquids or high densities liquids. </p>
                                <p>Their operation is through compressed air, which helps to avoid heating risks and turns them into safe equipments.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Metallic: aluminum, stainless steel, bronze or cast iron.</li>
                                    <li>Not metallic: cpvc, pvdf, polypropylene.</li>
                                    <li>Doesn’t require electric installation.</li>
                                    <li>Pneumatic supply.</li>
                                    <li>Doesn’t have an electric shock risk.</li>
                                    <li>Ideal for managing high viscosity substances.</li>
                                    <li>Easy to transport.</li>
                                    <li>Minimum maintenance.</li>
                                    <li>Quiet operation.</li>
                                    <li>One year warranty.</li>
                                </ul>
                            `,
                        },
                    },
                    1: {//Bombas centrífugas
                        title: 'Centrifugal pumps',
                        0:{
                            id: 'bombas-centrifugas',
                            title: 'Centrifugal pumps',
                            description: `
                                <p>The “Price Pump” centrifugal pumps consist of customized equipments for chemical applications and special systems.</p>
                                <p>They are on different materials, like plastic, cast iron, stainless steel and bronze.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Metallic: aluminum, stainless steel, bronze or cast iron.</li>
                                    <li>Not metallic: cpvc y pvdf.</li>
                                    <li>Mechanical seal of special materials for different applications and temperatures.</li>
                                    <li>One or more drivers.</li>
                                    <li>Engine according to application requirements (odp, tefc, xp).</li>
                                    <li>Long durability parts.</li>
                                    <li>One year warranty.</li>
                                </ul>
                            `,
                        },
                    }
                },
                1: {//Bombas de vacio
                    id: 'bombas-de-vacio',
                    title: 'Vacuum Pumps',
                    description: `
                        <p>The “Sullair” vacuum pumps has an asymmetric screws system of positive movement, flooded on oil, cool by air, and of high volumetric efficiency and constant vacuum.</p>
                        <p>Thanks to its robust design they are allowed to make industrial applications, operate 24/7, from 5 hp to 150 hp.</p>
                    `,
                    0: {
                        title: 'Vacuum Pumps',
                        0: {//Bombas de vacío
                            id: 'bombas-de-vacio',
                            title: 'Vacuum Pumps',
                            description: `
                                <p>The “Sullair” vacuum pumps has an asymmetric screws system of positive movement, flooded on oil, cool by air, and of high volumetric efficiency and constant vacuum.</p>
                                <p>Thanks to its robust design they are allowed to make industrial applications, operate 24/7, from 5 hp to 150 hp.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Doesn’t need foundation.</li>
                                    <li>Doesn’t vibrate.</li>
                                    <li>Low velocity of screws and engine. </li>
                                    <li>Low maintenance.</li>
                                    <li>The rotors don’t touch between them. </li>
                                    <li>Package type ready to start.</li>
                                    <li>Operation indicators and maintenance complete set. Local service and local spare parts.</li>
                                    <li>Two years warranty on compressor element and engine, one year on the other components.</li>
                                </ul>
                            `
                        },
                    }
                },
                2: {//Sistemas e tratamiento de aire
                    id: 'sistemas-de-tratamiento-de-aire',
                    title: 'Air Treatment Systems',
                    description: `
                        <p>We have different types of “Sullair” air treatment systems, which consist on cyclic and noncyclic refrigeration dryers, desiccant dryers, and coalescing filters.</p>
                    `,
                    0 :{//Secadores refrigerativos
                        title: 'Cyclic refrigerative',
                        0: {//Secadores refrigerativos cíclicos
                            id: 'secadores-refrigerativos-ciclicos',
                            title: 'Cyclic refrigerative',
                            description: `
                                <p>They are ideal for variable flow operations, because they use solenoid valves that close during the low demand periods, catching refrigerant on the heat exchanger, which is completely isolated, and act as thermal mass; so once the mass is cooled to a preset temperature the dryer turns off, and turns on when the dew point rises above the allowed value, resulting in energy savings at low demand.</p>
                            `,
                            features: `
                                <ul>
                                    <li>The  range is from 30 to 6,000 scfm.</li>
                                    <li>The intelligent construction allows not only the highest functionality, but also reliable and low-cost operation.</li>
                                    <li>Maximum working pressure: 200 psi.</li>
                                    <li>Maximum operating temperature: 50ºC.</li>
                                    <li>Removes up to 99.2% air moisture.</li>
                                    <li>Temporize drain for condensate.</li>
                                    <li>Connections: from ½" to 8".</li>
                                </ul>
                            `,
                        },
                        1: {//Secadores refrigerativos no cíclicos
                            id: 'secadores-refrigerativos-no-ciclicos',
                            title: 'Non-cyclic refrigerative dryers',
                            description: `
                                <p>They are ideal for continuous operation with a fixed load, because they keep a constant power consumption.</p>
                                <p>On the other side, they use a hot gas bypass to keep a stable dew point under typical operating conditions, so the amount of refrigerant circulating through the dryer can be controlled.</p>
                                <p>Finally, they have a heat exchanger with vertical design, obtaining a downward condensate flow that keeps them at the lowest point.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Two-year cover-to-cover warranty.</li>
                                    <li>They are in the range of 10 to 10,000 scfm.</li>
                                    <li>Maximum working pressure: 200 psi.</li>
                                    <li>Maximum operating temperature: 50ºC.</li>
                                    <li>Removes 99.2% of moisture from the air.</li>
                                    <li>Timed trap or 0 losses for condensate.</li>
                                    <li>Connections from ½" to 10".</li>
                                </ul>
                            `,
                        },
                        2: {//Secadores regenerativos
                            id: 'secadores-regenerativos',
                            title: 'Dessicant Dryers',
                            description: `
                                <p>This kind of "Sullair" dryers are ideal for applications where extremely low dew points (-40ºF / -40ºC) are required, "Sullair" offers a reliable system to ensure air quality (clean and dry) for the most critical applications such as food, pharmaceutical or electronic industries</p>
                            `,
                            features: `
                                <ul>
                                    <li>Stainless steel support for the alumina and a diffuser mesh for better air distribution.</li>
                                    <li>Fully automatic, pressure swing, twin tower compressed air dryer.</li>
                                    <li>High-performance, steel-bodied, butterfly-type inlet isolation valve.</li>
                                    <li>UL/CU electrical certifications.</li>
                                    <li>NEMA 12 electrical panel as standard.</li>
                                    <li>Towers are protected with a textured, high solids, acrylic polyurethane abrasion and impact resistant coating.</li>
                                    <li>1 year full warranty on dryer operation.</li>
                                    <li>Towers are welded carbon steel construction designed in accordance with ASME.</li>
                                    <li>Thermal relief valves and easily accessible desiccant fill and drain ports included.</li>
                                </ul>
                            `
                        },
                    },
                    1: { //Filtros coalescentes
                        title: 'Coalesing filters',
                        0:{//Filtros coalescentes
                            id: 'filtros-coalescentes',
                            title: 'Coalesing filters',
                            description: `
                                <p>These kinds of “Sullair” filters help to remove the amount of liquid moisture and oil mist in the compressed air stream, as they are designed to meet the requirements necessary to provide the right quality for compressed air on each application.</p>
                                <p>In addition, they use aerospace technology, as "Sullair" has optimized the air flow path through the metal casing and internal element significantly reducing turbulence and pressure losses.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Maximum working pressure: 232 psi.</li>
                                    <li>Maximum operating temperature: 50ºC.</li>
                                    <li>FXF filter can retain particles down to 1 micron and 0.5ppm of lubricant carryover.</li>
                                    <li>FXH filter can retain particles down to 0.01 micron and 0.01ppm of lubricant carryover.</li>
                                    <li>FXC filter can retain particles down to 0.01 micron and 0.003ppm of lubricant carryover.</li>
                                </ul>
                            `,
                        },
                    }
                },
                3: { //Generadores de nitrogeno
                    id: 'generadores-de-nitrogeno',
                    title: 'Nitrogen Generators',
                    description: `
                        <p>We have PSA and membrane type nitrogen generators, both from "Holtec Gas Systems".</p>
                        <p>In the case of PSA technology, they work with the concept of adsorption, a process by which molecules temporarily adhere to the surface of the materials with which they are in contact.</p>
                        <p>Whereas the membrane-type technology uses one or more permeable hollow fiber-type membrane modules, to separate compressed air into a high-pressure nitrogen product stream, and a low-pressure oxygen-enriched waste stream.</p>
                    `,
                    0: {//Generadores PSA
                        title: 'PSA type nitrogen generators',
                        description: `
                            <p>PSA nitrogen generators consist of two towers with adsorbent material, also called carbon molecular sieve (CMS), which is specifically produced to have pore sizes similar to the size of an oxygen molecule (which compared to the relatively larger nitrogen molecule)</p>
                        `,
                        0: {//Generador serie “Niagara” (PSA)
                            id: 'niagara',
                            title: 'Niagara Series Generator (PSA)',
                            description: `
                                <p>These PSA type nitrogen generators are designed with the flexibility and capacity to meet large nitrogen demands. </p>
                                <p>They have purity capacities greater than 99.9995%, and flow rates from 9 to 1,900 scfm.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Advanced PSA technology, with purities up to 99.9995% (5 ppm O2 content).</li>
                                    <li>Flexible capabilities to meet any requirement.</li>
                                    <li>Possibility of integrating a graphical user interface (HMI), animated, full color, and even remote monitoring of users, through any device with internet connection (mobile, tablet, PC).</li>
                                    <li>Remote troubleshooting by factory personnel.</li>
                                    <li>Automatic graphing of performance trends (real and historical time).</li>
                                </ul>
                            `,
                        },
                        1: {//Generador serie “Nitro Breeze” (PSA
                            id: 'nitro',
                            title: '“Nitro Breeze” Series Generator (PSA)',
                            description: `
                                <p>“Nitro Brezee" equipments are smaller, modular and portable PSA systems, designed for "One-Touch" operation, covering most industrial applications with minimum demands. They have capacities and purity greater than 99.9%, and flow rates from 1.5 to 59 scfm.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Fast delivery and installation.</li>
                                    <li>Advanced PSA technology, with purity up to 99.9% (10 ppm O2 content).</li>
                                    <li>Low to moderate demand capacities.</li>
                                    <li>Modular design.</li>
                                    <li>The installation is adapted through standard doors with male threads of different diameters.</li>
                                    <li>Password protected screens for different user levels.</li>
                                </ul>
                            `
                        }
                    },
                    1: {//Generadores Membrana
                        title: 'Membrane type nitrogen generators',
                        description: `
                            <p>In this type of generators, the typical feed air pressure is 125 psig (8.6 barg), so operation at higher pressure will increase product flow. </p>
                            <p>In addition, the typical product purity for our membrane type generators will be 95% to 99.5%.</p>
                        `,
                        0:{//Generador serie “Amazónica”
                            id: 'amazonica',
                            title: '“Amazónica” series generator',
                            description: `
                                <p>The "Amazónica" series generators are membrane type equipments, designed to meet large capacity requirements. They have a constant flux, molecular design, for any requirement from 9 to 1,900 scfm and moderate purity.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Continuous flow membrane technology.</li>
                                    <li>Constant pressure process.</li>
                                    <li>N2 purities up to 99.5% (0.5% O2 content).</li>
                                    <li>Flexible capabilities to meet any requirement.</li>
                                    <li>Easy connectivity to plant control systems (DCS).</li>
                                    <li>Limited mechanical components to reduce complexity.</li>
                                </ul>
                            `
                        },
                        1:{//Generador serie “Spirit”
                            id: 'spirit',
                            title: '“Spirit" series generator',
                            description: `
                                <p>"Spirit" is a membrane type equipment that can count on a compact design facilitating its installation and use, which refers to the flexibility and cost-effectiveness of low to moderate capacities, supplying only the nitrogen that is needed.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Continuous flow membrane technology.</li>
                                    <li>Minimal space required for easy system integration.</li>
                                    <li>Capacities from 2 to 33 scfm.</li>
                                    <li>N2 purities, up to 99.5% (0.5% O2 content).</li>
                                    <li>Basic designs do not require electricity for operation (an easy solution for hazardous area requirements).</li>
                                </ul>
                            `
                        }
                    }
                },
                4: { //Limpieza industrial
                    id: 'limpieza-industrial',
                    title: 'Industrial Cleaning',
                    description: `
                        <p>We have customized industrial cleaning equipment and "Sioux" thermal processes, all designed to withstand the most extreme conditions. </p>
                    `,
                    0: {//Calentadores de solución
                        title: 'Solution heaters',
                        0: {//Calentadores de solución
                            id: 'calentadores-de-solucion',
                            title: 'Solution heaters',
                            description: `
                                <p>“Sioux" solution heaters are designed and manufactured with 100% stainless steel parts, which helps to prevent corrosion when heating acidic solutions. Their robust and reliable design provides a fast and constant supply of solution at the required temperature. In addition, the units can operate continuously, so no preheating or recovery time is required.</p>
                                <p>In mining by using solution heaters in the leaching process for the extraction of precious metals, increasing the separation rate and reducing operating times.</p>
                            `,
                            features: ''
                        },
                    },
                    1: { //Calentadores de agua
                        id: 'calentadores-de-agua',
                        title: 'Water heaters',
                        0: {//Calentadores de agua
                            title: 'Water heaters',
                            description: `
                                <p>"Sioux” has been designing and manufacturing water heaters for various applications and needs for over 50 years. The heaters provide a constant flow and temperature reaching up to 37°C (100°F) within 3 to 5 minutes (depending on the model).</p>
                                <p>These heater machines are manufactured to ASME section IV code, and are registered with the National Board of Boiler and Pressure Vessel Inspectors, and are also UL508A and CAN/CSA-C22.2 No. 14-10 approved, ensuring that the control panels meet UL and CSA standards.</p>
                            `,
                            features:{
                                0: {
                                    title: 'M SERIES',
                                    description: `
                                        <li>Compact design for low capacity applications.</li>
                                        <li>Heat generation for 1M and up to 3M BTU/Hr.</li>
                                        <li>Voltage required 230-460/3F/60Hz.</li>
                                        <li>The heater can operate with fuel, LP gas or natural gas.</li>
                                        <li>It is protected against low water flow.</li>
                                        <li>Protection against high water temperature.</li>
                                        <li>Pressure gauges and thermometers to monitor inlet and outlet water temperature and pressure.</li>
                                        <li>Screen type strainer to prevent solid particles from entering the water heater water supply. </li>
                                    `
                                },
                                1: {
                                    title: 'HM SERIES',
                                    description: `
                                        <li>Designed for higher capacity applications, with digital temperature control.</li>
                                        <li>Heat generation for 1.7M and up to 5M BTU/Hr.</li>
                                        <li>Voltage required 230-460/3F/60Hz.</li>
                                        <li>El calentador puede operar con combustible, gas LP o gas natural.</li>
                                        <li>It is protected against low water flow.</li>
                                        <li>Protection against high water temperature.</li>
                                        <li>Pressure gauges and thermometers to monitor inlet and outlet water temperature and pressure.</li>
                                        <li>Screen type strainer to prevent solid particles from entering the water heater supply. </li>
                                    `,
                                },
                                2: {
                                    title: 'Hybrid water heaters',
                                    description: `
                                        <li>The spiral type heater together with the conventional direct fired heater provides the best efficiency available.</li>
                                        <li>Reaching temperatures up to 91°C (195°F) without producing steam.</li>
                                        <li>It has a water recirculation system, maintaining a stable temperature in the storage tank.</li>
                                        <li>Voltage required: 230-460/3F/60Hz.</li>
                                        <li>It is protected against low water flow.</li>
                                        <li>Protection against high water temperature.</li>
                                        <li>Pressure gauges and thermometers to monitor inlet and outlet water temperature and pressure.</li>
                                        <li>Screen type strainer to prevent solid particles from entering the water heater water supply. </li>
                                    `,
                                },
                                3: {
                                    title: 'HWP & PWP Series',
                                    description: `
                                        <li>It features the M series heater in conjunction with a water storage tank, which can be either a large capacity 1,000 gallon (HWP) for remote applications with limited resources or a smaller capacity 300 gallon (PWP) water storage tank.</li>
                                        <li>Heat generation for 1M and up to 3M BTU/Hr.</li>
                                        <li>Voltage required 230-460/3F/60Hz.</li>
                                        <li>The heater can operate with fuel, LP gas or natural gas.</li>
                                        <li>It is protected against low water flow.</li>
                                        <li>Protection against high water temperature.</li>
                                        <li>Pressure gauges and thermometers to monitor inlet and outlet water temperature and pressure.</li>
                                        <li>Screen type strainer to prevent solid particles from entering the water heater water supply.</li>
                                    `,
                                },
                                4: {
                                    title: 'M-415',
                                    description: `
                                        <li>Designed for a mobile application (fully portable).</li>
                                        <li>This heater can be installed in trucks or vans for transportation.</li>
                                        <li>It is available for connection to a voltage of 12 VDC or 120 VAC.</li>
                                        <li>Flame protection and monitoring system.</li>
                                        <li>It has a ½ hp TEFC motor for the heater.</li>
                                        <li>Heat generation for 415,000 BTU / hr.</li>
                                        <li>Heats up to 500 gallons to 100°F (37.8°C) in one hour.</li>
                                        <li>Temperature and pressure gauges.</li>
                                    `,
                                },
                                5: {
                                    title: 'All electric',
                                    description: `
                                        <li>Designed for specific applications where fuels such as diesel, LP gas or natural gas cannot be used for water heating.</li>
                                        <li>Capable of heating water up to 93°C (200°F).</li>
                                        <li>The heating element requires a three-phase 480V/3F/60Hz power supply.</li>
                                        <li>It has protection against low water flow and high temperature.</li>
                                        <li>It does not generate polluting gas emissions.</li>
                                    `,
                                },
                            }
                        },
                        1:{
                            id: 'serie-m',
                            title: 'M SERIES',
                            description: `
                                <p>"Sioux” has been designing and manufacturing water heaters for various applications and needs for over 50 years. The heaters provide a constant flow and temperature reaching up to 37°C (100°F) within 3 to 5 minutes (depending on the model).</p>
                                <p>These heater machines are manufactured to ASME section IV code, and are registered with the National Board of Boiler and Pressure Vessel Inspectors, and are also UL508A and CAN/CSA-C22.2 No. 14-10 approved, ensuring that the control panels meet UL and CSA standards. </p>
                            `,
                            features: `
                                <ul>
                                    <li>Compact design for low capacity applications.</li>
                                    <li>Heat generation for 1M and up to 3M BTU/Hr.</li>
                                    <li>Voltage required 230-460/3F/60Hz.</li>
                                    <li>The heater can operate with fuel, LP gas or natural gas.</li>
                                    <li>It is protected against low water flow.</li>
                                    <li>Protection against high water temperature.</li>
                                    <li>Pressure gauges and thermometers to monitor inlet and outlet water temperature and pressure.</li>
                                    <li>Screen type strainer to prevent solid particles from entering the water heater water supply. </li>
                                </ul>
                            `
                        },
                        2:{
                            id: 'serie-hm',
                            title: 'HM SERIES',
                            description: `
                                <p>"Sioux” has been designing and manufacturing water heaters for various applications and needs for over 50 years. The heaters provide a constant flow and temperature reaching up to 37°C (100°F) within 3 to 5 minutes (depending on the model).</p>
                                <p>These heater machines are manufactured to ASME section IV code, and are registered with the National Board of Boiler and Pressure Vessel Inspectors, and are also UL508A and CAN/CSA-C22.2 No. 14-10 approved, ensuring that the control panels meet UL and CSA standards. </p>
                            `,
                            features: `
                                <ul>
                                    <li>Designed for higher capacity applications, with digital temperature control.</li>
                                    <li>Heat generation for 1.7M and up to 5M BTU/Hr.</li>
                                    <li>Voltage required 230-460/3F/60Hz.</li>
                                    <li>El calentador puede operar con combustible, gas LP o gas natural.</li>
                                    <li>It is protected against low water flow.</li>
                                    <li>Protection against high water temperature.</li>
                                    <li>Pressure gauges and thermometers to monitor inlet and outlet water temperature and pressure.</li>
                                    <li>Screen type strainer to prevent solid particles from entering the water heater supply. </li>
                                </ul>
                            `
                        },
                        3:{
                            id: 'calentadores-de-agua-hibridos',
                            title: 'Hybrid water heaters',
                            description: `
                                <p>"Sioux” has been designing and manufacturing water heaters for various applications and needs for over 50 years. The heaters provide a constant flow and temperature reaching up to 37°C (100°F) within 3 to 5 minutes (depending on the model).</p>
                                <p>These heater machines are manufactured to ASME section IV code, and are registered with the National Board of Boiler and Pressure Vessel Inspectors, and are also UL508A and CAN/CSA-C22.2 No. 14-10 approved, ensuring that the control panels meet UL and CSA standards. </p>
                            `,
                            features: `
                                <ul>
                                    <li>The spiral type heater together with the conventional direct fired heater provides the best efficiency available.</li>
                                    <li>Reaching temperatures up to 91°C (195°F) without producing steam.</li>
                                    <li>It has a water recirculation system, maintaining a stable temperature in the storage tank.</li>
                                    <li>Voltage required: 230-460/3F/60Hz.</li>
                                    <li>It is protected against low water flow.</li>
                                    <li>Protection against high water temperature.</li>
                                    <li>Pressure gauges and thermometers to monitor inlet and outlet water temperature and pressure.</li>
                                    <li>Screen type strainer to prevent solid particles from entering the water heater water supply. </li>
                                </ul>
                            `
                        },
                        4:{
                            id: 'serie-hwp-pwp',
                            title: 'HWP & PWP Series',
                            description: `
                                <p>"Sioux” has been designing and manufacturing water heaters for various applications and needs for over 50 years. The heaters provide a constant flow and temperature reaching up to 37°C (100°F) within 3 to 5 minutes (depending on the model).</p>
                                <p>These heater machines are manufactured to ASME section IV code, and are registered with the National Board of Boiler and Pressure Vessel Inspectors, and are also UL508A and CAN/CSA-C22.2 No. 14-10 approved, ensuring that the control panels meet UL and CSA standards. </p>
                            `,
                            features: `
                                <ul>
                                    <li>It features the M series heater in conjunction with a water storage tank, which can be either a large capacity 1,000 gallon (HWP) for remote applications with limited resources or a smaller capacity 300 gallon (PWP) water storage tank.</li>
                                    <li>Heat generation for 1M and up to 3M BTU/Hr.</li>
                                    <li>Voltage required 230-460/3F/60Hz.</li>
                                    <li>The heater can operate with fuel, LP gas or natural gas.</li>
                                    <li>It is protected against low water flow.</li>
                                    <li>Protection against high water temperature.</li>
                                    <li>Pressure gauges and thermometers to monitor inlet and outlet water temperature and pressure.</li>
                                    <li>Screen type strainer to prevent solid particles from entering the water heater water supply.</li>
                                </ul>
                            `
                        },
                        5:{
                            id: 'M-415',
                            title: 'M-415',
                            description: `
                                <p>"Sioux” has been designing and manufacturing water heaters for various applications and needs for over 50 years. The heaters provide a constant flow and temperature reaching up to 37°C (100°F) within 3 to 5 minutes (depending on the model).</p>
                                <p>These heater machines are manufactured to ASME section IV code, and are registered with the National Board of Boiler and Pressure Vessel Inspectors, and are also UL508A and CAN/CSA-C22.2 No. 14-10 approved, ensuring that the control panels meet UL and CSA standards. </p>
                            `,
                            features: `
                                <ul>
                                    <li>Designed for a mobile application (fully portable).</li>
                                    <li>This heater can be installed in trucks or vans for transportation.</li>
                                    <li>It is available for connection to a voltage of 12 VDC or 120 VAC.</li>
                                    <li>Flame protection and monitoring system.</li>
                                    <li>It has a ½ hp TEFC motor for the heater.</li>
                                    <li>Heat generation for 415,000 BTU / hr.</li>
                                    <li>Heats up to 500 gallons to 100°F (37.8°C) in one hour.</li>
                                    <li>Temperature and pressure gauges.</li>
                                </ul>
                            `
                        },
                        6:{
                            id: 'all-electric',
                            title: 'All electric',
                            description: `
                                <p>"Sioux” has been designing and manufacturing water heaters for various applications and needs for over 50 years. The heaters provide a constant flow and temperature reaching up to 37°C (100°F) within 3 to 5 minutes (depending on the model).</p>
                                <p>These heater machines are manufactured to ASME section IV code, and are registered with the National Board of Boiler and Pressure Vessel Inspectors, and are also UL508A and CAN/CSA-C22.2 No. 14-10 approved, ensuring that the control panels meet UL and CSA standards. </p>
                            `,
                            features: `
                                <ul>
                                    <li>Designed for specific applications where fuels such as diesel, LP gas or natural gas cannot be used for water heating.</li>
                                    <li>Capable of heating water up to 93°C (200°F).</li>
                                    <li>The heating element requires a three-phase 480V/3F/60Hz power supply.</li>
                                    <li>It has protection against low water flow and high temperature.</li>
                                    <li>It does not generate polluting gas emissions.</li>
                                </ul>
                            `
                        },
                    },
                    2: {//Generadores de vapor
                        id: 'generadores-de-vapor',
                        title: 'Steam generators',
                        0: {//Generadores de vapor
                            title: 'Steam generators',
                            description: `
                                <p>“Sioux Steam-Flo” steam generators are a perfect source of large quantities of steam at low pressure, producing it in minutes.</p>
                                <p>They are often used in commercial greenhouses, nurseries and industrial applications, such as heating steam jackets, thawing pipes and valves, curing concrete and heating tank cars for unloading.</p>
                            `,
                            features: `
                                <ul>
                                    <li>They provide continuous and unlimited steam.</li>
                                    <li>Easy to maintain and built to last.</li>
                                    <li>Units available for stationary applications.</li>
                                    <li>Available for use on trailer units for mobile applications.</li>
                                    <li>They can be equipped with an optional water tank in order to achieve a completely autonomous operation.</li>
                                    <li>The boiler is registered with the National Board of Boiler and Pressure Vessel Inspectors, and therefore meets the requirements of CSD-1 of the ASME Code and is constructed in accordance with Section IV of the ASME Code.</li>
                                    <li>It is UL508A and CAN / CSA-C22.2 No. 14-10 approved, ensuring that the entire control panel assembly complies with UL and CSA standards.</li>
                                </ul>
                            `,
                        }
                    },
                    3: {//Hidrolavadoras
                        id: 'hidrolavadoras',
                        title: 'Pressure washers',
                        0: {//Hidrolavadoras
                            title: 'Pressure washers',
                            description: `
                                <p>“Sioux" pressure washers and steam cleaners consist of equipment designed to withstand the most extreme conditions.</p>
                                <p>Hot and cold pressure washers are ideal for removing dirt. Steam cleaners are excellent for cleaning grease and oil, sterilizing, disinfecting and dissolving resins.</p>
                            `,
                            features: {
                                titles: {
                                    description: 'Description:',
                                    features: 'Characteristics:'
                                },
                                0: {
                                    title: '“EN” Series',
                                    description: `
                                        <li>The "EN" series features an extra heavy duty industrial steel frame, which is built for extremely demanding operating environments. These units are our explosion proof option that is available to allow the unit to be used in hazardous (classified) locations, which according to OSHA 1910.303 (a) can be found in aircraft hangars, gasoline dispensing and bulk storage plants, as well as paint finishing process plants, health care facilities, agricultural plants, marinas, shipyards, and petroleum and chemical processing plants.</li>
                                    `,
                                    features: `
                                        <li>Designed for high demand applications.</li>
                                        <li>Fixed operation design.</li>
                                        <li>It does not generate polluting gas emissions.</li>
                                        <li>Provides hot water in less than 1 minute, and steam in less than 3 minutes.</li>
                                    `
                                },
                                1: {
                                    title: '“E” Series',
                                    description: `
                                        <li>The "E" series units are for markets that require the same cleaning power as the "EN" series, but in a more compact design.</li>
                                    `,
                                    features: `
                                        <li>They have a rugged industrial steel frame and use components similar to the "EN" series.</li>
                                        <li>They are ideal for applications where portability and space are a concern, such as pharmaceutical, chemical, food processing and general manufacturing facilities.</li>
                                    `
                                },
                                2: {
                                    title: 'Fuel-powered high pressure washers',
                                    description: `
                                        <p>Engine-driven hot water pressure washers are available as stand-alone units with an integrated pressure washer, water tank and gasoline or diesel engine on a heavy-duty skid or on the road trailer.</p>
                                        <p>Conventional pressure washers are available with an electric or internal combustion engine for the water pump and a fuel-driven burner. These machines can operate with cold water, hot water and steam delivery option. For stationary use or fully portable in their internal combustion engine version.</p>
                                    `,
                                    features: `
                                        <li>They include the option of steam cleaning, hot pressure washing and cold pressure rinsing in one compact unit.</li>
                                        <li>They are excellent for cleaning in remote locations.</li>
                                        <li>It has the option for trailer mounting making it fully portable.</li>
                                        <li>Provides hot water in less than 1 minute, and steam in less than 3 minutes.</li>
                                    `
                                },
                            }
                        },
                        1: {
                            id: 'serie-en',
                            title: '“EN” Series',
                            description: `
                                <p>The "EN" series features an extra heavy duty industrial steel frame, which is built for extremely demanding operating environments. These units are our explosion proof option that is available to allow the unit to be used in hazardous (classified) locations, which according to OSHA 1910.303 (a) can be found in aircraft hangars, gasoline dispensing and bulk storage plants, as well as paint finishing process plants, health care facilities, agricultural plants, marinas, shipyards, and petroleum and chemical processing plants.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Designed for high demand applications.</li>
                                    <li>Fixed operation design.</li>
                                    <li>It does not generate polluting gas emissions.</li>
                                    <li>Provides hot water in less than 1 minute, and steam in less than 3 minutes.</li>
                                </ul>
                            `
                        },
                        2: {
                            id: 'serie-e',
                            title: '“E” Series',
                            description: `
                                <p>The "E" series units are for markets that require the same cleaning power as the "EN" series, but in a more compact design.</p>
                            `,
                            features: `
                                <ul>
                                    <li>They have a rugged industrial steel frame and use components similar to the "EN" series.</li>
                                    <li>They are ideal for applications where portability and space are a concern, such as pharmaceutical, chemical, food processing and general manufacturing facilities.</li>
                                </ul>
                            `
                        },
                        3: {
                            id: 'lavadoras-de-alta-presion-a-combustible',
                            title: 'Fuel-powered high pressure washers',
                            description: `
                                <p>Engine-driven hot water pressure washers are available as stand-alone units with an integrated pressure washer, water tank and gasoline or diesel engine on a heavy-duty skid or on the road trailer.</p>
                                <p>Conventional pressure washers are available with an electric or internal combustion engine for the water pump and a fuel-driven burner. These machines can operate with cold water, hot water and steam delivery option. For stationary use or fully portable in their internal combustion engine version.</p>
                            `,
                            features: `
                                <ul>
                                    <li>They include the option of steam cleaning, hot pressure washing and cold pressure rinsing in one compact unit.</li>
                                    <li>They are excellent for cleaning in remote locations.</li>
                                    <li>It has the option for trailer mounting making it fully portable.</li>
                                    <li>Provides hot water in less than 1 minute, and steam in less than 3 minutes.</li>
                                </ul>
                            `
                        },
                    }
                },
                5: {//Sopladores
                    id: 'sopladores',
                    title: 'Turbo Blowers',
                    description: `
                        <p>We have different types of industrial blowers of the brand "Lone Star Blower". Ideal for applications such as, Mining Industry, Water Treatment and Cement Factories, etc.</p>
                    `,
                    0: {
                        title: 'Geared blower',
                        0:{//Soplador con engranaje
                            id: 'soplador-con-engranaje',
                            title: 'Geared blower',
                            description: `
                                <p>These "Lone Star" industrial turbo blowers, with integral gearing, operate in the 100 hp to 6,000 hp range.</p>
                            `,
                            features: `
                                <ul>
                                    <li>High aerodynamic efficiency throughout the entire flow range.</li>
                                    <li>Constant speed.</li>
                                    <li>Reduction to 40% of the design flow at constant speed.</li>
                                    <li>Variable flow by means of discharge diffuser vanes, inlet guide vanes.</li>
                                    <li>Special bearings for long service life and minimum maintenance.</li>
                                    <li>Oil-free compression.</li>
                                    <li>Low operating and maintenance costs.</li>
                                </ul>
                            `
                        },
                        1:{//Soplador multietapa “Lone Star”
                            id: 'lone-star',
                            title: '“Lone Star" multi-stage blower',
                            description: `  
                                <p>These "Lone Star" industrial turbo blowers are designed from 20 hp to 3,000 hp, with 1 to 10 compression stages, guaranteeing maximum efficiency.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Large diameter impellers that produce high velocity to maintain pressure.</li>
                                    <li>Designs from 1 to 10 impellers.</li>
                                    <li>It can operate with air and gas.</li>
                                    <li>Highly flexible design to meet air demand.</li>
                                    <li>20 hp - 3,000 hp, 2 - 25 PSI, 300 - 45,000 SCFM.</li>
                                    <li>Direct-coupled motor: no gears or belts.</li>
                                    <li>Low speed.</li>
                                    <li>Self-lubricating roller bearings.</li>
                                    <li>Minimal maintenance.</li>
                                    <li>Quiet operation <85 dba.</li>
                                </ul>
                            `
                        },
                        2: {//Soplador sin engranaje TurboMax
                            id: 'turbomax',
                            title: 'TurboMax gearless blower',
                            description: `
                                <p>These "Lone Star" industrial turbo blowers operate gearless, and from 20 hp to 1,000 hp. They are also compact and easy to install, as the controller, exhaust valve, frequency converter and all sensors are completely integrated into one housing, all ready to be connected to the compressed air system.</p>
                            `,
                            features: `
                                <ul>
                                    <li>20 to 40% more energy efficiency, which offers less than a 3-year return on investment.</li>
                                    <li>75 to 85 dba.</li>
                                    <li>It typically uses 1/3 of the space of existing technologies.</li>
                                    <li>It does not have scheduled lubrication, alignment or maintenance along with filter change.</li>
                                    <li>With just adding power it is ready to go.</li>
                                    <li>It has a high-speed permanent magnet motor, offering superior efficiency at partial loads.</li>
                                </ul>
                            `
                        }
                    }
                },
                6: {//Sistemas de aire comprimido
                    id: 'sistemas-de-aire-comprimido',
                    title: 'Compressed air systems',
                    description: ``,
                    0:{
                        title: 'Oil-free compressors',
                        0: {//Compresores DSP
                            id: 'dsp',
                            title: 'DSP compressors',
                            description: `
                                <p>The “Sullair” DSP Series offers advanced oil-free processes right where you need them. Manufactured with state-of-the-art Japanese technology, the DSP is designed to deliver oil-free air reliably and efficiently, complying with ISO 8573-1 as Class 0.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Class 0 oil-free air.</li>
                                    <li>Star-delta or VSD connected starter.</li>
                                    <li>Phase monitor.</li>
                                    <li>Ability to operate at an altitude of up to 9000 feet.</li>
                                    <li>Oil mist recuperator in gearbox (patented).</li>
                                    <li>Motorized isolation valve at the discharge that prevents condensate return when the equipment is out of operation.</li>
                                    <li>The variable models (VSD) feature a patented design without inlet valve.</li>
                                    <li>Patented high-efficiency pre-cooler design.</li>
                                </ul>
                            `
                        },
                        1: {//Compresores tipo Scroll
                            id: 'scroll',
                            title: 'Scroll compressors',
                            description: `
                                <p>The "Sullair" Scroll compressors are characterized by their oil-free and extremely quiet operation, since their operation generates less than 60 dB(A). This is because the process is carried out with the help of two spirals, one with orbital rotation and the other fixed, so that the air enters the compression chamber and the volume is gradually reduced as the orbiting spiral rotates. The movement of the spiral compresses the air in the atmosphere, creating oil-free and contaminant-free air.</p>
                            `,
                            features: `
                                <ul>
                                    <li>They range from 2 hp to 44 hp.</li>
                                    <li>They are designed with a spiral wound, labyrinth seal system, which reduces air leakage while extending service intervals.</li>
                                    <li>They are on the oil-free equipment range, which comply with the ISO 8573-1 standard and offer class 0 air in their compression process, since they do not require oil to operate.</li>
                                    <li>Air pressures up to 145 psig.</li>
                                    <li>Multi-stage equipment to adapt the air delivery to the application demand.</li>
                                    <li>Standard cabin.</li>
                                    <li>Mechanical and electrical vibration isolation.</li>
                                    <li>​​Designed to be placed at the point where the air is needed.</li>
                                    <li>Easy access for inspection and maintenance.</li>
                                </ul>
                            `,
                        },
                        2: {//Compresores centrífugos
                            id: 'centrifugos',
                            title: 'Centrifugal compressors',
                            description: `
                                <p>The "Sullair'' centrifugal compressors are specifically designed for each requirement, considering flow and pressure, always looking for the lowest power required. It is of a simple design that reduces the parts needed for maintenance.</p>
                            `,
                            features: `
                                <ul>
                                    <li>They are designed for a power range, from 300 hp to 30,000 hp.</li>
                                    <li>They are available in multi-stage design.</li>
                                    <li>Optimum combination of components, from impellers made of titanium or stainless steel, to radial bearing bearings with oscillating segments.</li>
                                    <li>They operate with high efficiency, resulting in lower energy consumption.</li>
                                    <li>Minimal maintenance, since many of the components have a very low level of wear.</li>
                                </ul>
                            `
                        },
                        
                    },
                    1: {
                        title: 'Oil-injected compressors',
                        0: {//Compresores Encapsulados
                            id: 'encapsulados',
                            title: 'Encapsulated Compressors',
                            description: `
                                <p>These "Sullair" single-stage screw compressors feature lubricant injection, high volumetric and constant efficiency. They operate with a modulating system that can reduce energy consumption by reducing air intake, adapting discharge to plant air demand and lightening the load on the main motor. </p>
                                <p>It is supplied with "SULLUBE" synthetic fluid, based on polyglycol that does not contain hydrocarbons, which prevents the formation of varnish and carbon, with prolonged action, allowing use in periods of 10,000 hours or 1 year of operation. Compressors from 5 hp to 25 hp can be handled with a complete kit, which includes tank, filters, acoustic house and everything a compression system needs to operate, maintaining its compact format.</p>
                            `,
                            features: `
                                <ul>
                                    <li>They range from 5hp to 25 hp.</li>
                                    <li>Permanent flow rate (volumetric efficiency does not vary).</li>
                                    <li>Constant pressure by modulation.</li>
                                    <li>It doesn't need foundation, it doesn't vibrate.</li>
                                    <li>Complete set of indicators for operation and maintenance.</li>
                                    <li>Safety devices against anomalies in temperature, pressure or electric current consumption.</li>
                                    <li>Modulating control system and load-no load system, or automatic stop and start.</li>
                                    <li>Small mounting surface.</li>
                                </ul>
                            `
                        },
                        1:{//Compresor S-Energy
                            id: 's-energy',
                            title: 'S-Energy Compressor',
                            description: `
                                <p>These "Sullair" single-stage screw compressors feature application-appropriate capacity controls, lubricant injection, high volumetric and constant efficiency.</p>
                                <p>They are adapted to general plant demands, with the options of variable speed drive (VSD) and capacity drive (spiral valve) for unstable air consumption. In addition, this unit is supplied with “SULLUBE” synthetic fluid, based on polyglycol that does not contain hydrocarbons, which prevents the formation of varnish and carbon, with prolonged action, allowing it to be used for periods of 10,000 hours or 1 year of operation.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Robust design.</li>
                                    <li>Full Diamond warranty up to 10 years backed by "Sullair".</li>
                                    <li>It ranges from 25 hp to 100 hp.</li>
                                    <li>Low restriction air intake valve, for better cfm (m³/min) performance.</li>
                                    <li>Low drag air/oil separator design.</li>
                                    <li>High efficiency main motor and fan.</li>
                                    <li>NEMA 4 or 12 starter (varies by model).</li>
                                    <li>Compact footprint.</li>
                                    <li>Its high efficiency air filter provides 10 times better filtration than other filters.</li>
                                    <li>Spiral valve available in 75 hp and 100 hp.</li>
                                    <li>Modulating control system, load-no load system and optional variable speed drive.</li>
                                </ul>
                            `
                        },
                        2: {//Compresores de tornillo en una etapa
                            id: 'compresores-de-tornillo-en-una-etapa',
                            title: 'Single-stage screw compressors',
                            description: `
                                <p>“Sullair”s single-stage compressors feature application-appropriate capacity controls and operate on single-stage screw compressor technology, which features lubricant injection and high volumetric efficiency.</p>
                                <p>In addition, these compressors have an air or water cooling system, as required.</p>
                            `,
                            features: `
                                <ul>
                                    <li>They are supplied with synthetic SULLUBE fluid.</li>
                                    <li>They range from 125 hp to 350 hp.</li>
                                    <li>Modulating capacity control, spiral valve and VSD available.</li>
                                    <li>Permanent flow rate (volumetric efficiency does not vary).</li>
                                    <li>Constant pressure by capacity control.</li>
                                    <li>It does not need foundation, it does not vibrate.</li>
                                    <li>The rotors do not touch each other, due to the low speed in screws and motor.</li>
                                    <li>Ready-to-start package type, includes acoustical enclosure.</li>
                                    <li>Complete set of indicators for operation and maintenance.</li>
                                    <li>Safety devices against anomalies in temperature, pressure or electric current consumption.</li>
                                    <li>Color STS controller with 10" touch screen.</li>
                                    <li>AirLinx remote monitoring system.</li>
                                    <li>NEMA 4 star-delta starter as standard.</li>
                                    <li>Drain 0 air loss to save energy.</li>
                                </ul>
                            `
                        },
                        3: {//Compresores de tornillo en dos etapas
                            id: 'compresores-de-tornillo-en-dos-etapas',
                            title: 'Two-stage screw compressors',
                            description: `
                                <p>By combining two-stage compression with a "Sullair" spiral valve, unmatched energy efficiency is achieved at full load or partial loads, whereby the airend volume varies to match the air demand by progressively opening or closing the bypass ports on the unit. In this way, capacity is matched to system demand, thus reducing cyclic time and extending component life. In other words, two-stage equipment implements technology to its advantage, getting more air per hp and the best capacity control for partial loads.</p>
                            `,
                            features: `
                                <ul>
                                    <li>They are supplied with synthetic SULLUBE fluid.</li>
                                    <li>They range from 150 hp to 600 hp.</li>
                                    <li>Permanent flow rate (volumetric efficiency does not vary).</li>
                                    <li>Modulating capacity control, spiral valve and VSD available.</li>
                                    <li>Constant pressure by capacity control.</li>
                                    <li>They don’t need foundations, they don’t vibrate.</li>
                                    <li>The rotors don’t touch each other, due to the low speed in screws and motor.</li>
                                    <li>Package type, ready to start.</li>
                                    <li>Complete set of indicators for operation and maintenance.</li>
                                    <li>Safety devices against anomalies in temperature, pressure or electric current consumption. </li>
                                </ul>
                            `
                        }
                    }
                },
                7:{//Redes de distribución de aire comprimido
                    id: 'redes-de-distribucion-de-aire-comprimido',
                    title: 'Compressed air distribution networks',
                    description: `
                        <p>Our "Prevost" distribution networks for compressed air are designed and manufactured with 100% aluminum. In addition, the aluminum alloy used, combined with external epoxy paint and internal treatment, protects the pipes against the risks of oxidation and corrosion that can cause long-term problems.</p>
                    `,
                    0: {//Redes de distribución de aire comprimido
                        title: 'Compressed air distribution networks',
                        0: {//Tuberías de aluminio
                            id: 'tuberias-de-aluminio',
                            title: 'Aluminum piping',
                            description: `
                                <p>In this type of "Prevost" piping, its installation is recommended at a minimum height of 2.5 m from the ground, and considering a closed main circuit to maintain a stable pressure at each point of use. </p>
                            `,
                            features: `
                                <ul>
                                    <li>100% aluminum piping.</li>
                                    <li>Easy installation.</li>
                                    <li>Excellent friction coefficient.</li>
                                    <li>Compact, light and resistant material.</li>
                                    <li>Available in diameters from ½" to 4".</li>
                                </ul>
                            `,
                        },
                        1: {//Sistemas de filtración FRL’s
                            id: 'sistemas-de-filtracion-frls',
                            title: "FRL's Filtration Systems",
                            description: `
                                <p>FRL's "Prevost" filtration systems consist of a filter or set of filters designed to retain solids, remove a certain amount of liquids, regulate compressed air pressure and deliver the oil needed to internally lubricate the pneumatic tools.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Available in ¼" and up to 1" connections.</li>
                                    <li>Filtering capacity from 40 microns to 0.01 microns.</li>
                                    <li>Available for individual purchase or as a set.</li>
                                    <li>Optional mounting plates.</li>
                                </ul>
                            `
                        },
                        2: {//Conexiones de aluminio
                            id: 'conexiones-de-aluminio',
                            title: 'Aluminum connections',
                            description: `
                                <p>The "Prevost" connections are made of 100% aluminum, include a stainless steel toothed ring, double gasket type gasket which allows a perfect fixation avoiding slippage or air leaks.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Compact.</li>
                                    <li>Lightweight.</li>
                                    <li>Resistant.</li>
                                    <li>Available in diameters from ½" to 4".</li>
                                </ul>
                            `
                        },
                        3: {//Carretes retráctiles
                            id: 'carretes-retractiles',
                            title: 'Retractable reels',
                            description: `
                                <p>The "Prevost" retractable reels were designed to achieve a good organization in the work area.</p>
                            `,
                            features: `
                                <ul>
                                    <li>They can be placed on different surfaces.                                    </li>
                                    <li>They are practical.</li>
                                    <li>Quick and easy to install.</li>
                                    <li>Mounting plates that do not require surface drilling.</li>
                                    <li>Closed or open reels.</li>
                                    <li>Retractable system.</li>
                                    <li>Protective housing design offers high corrosion resistance.</li>
                                </ul>
                            `
                        },
                        4: {//Conectores rápidos anti-latigazo
                            id: 'conectores-rapidos-anti-latigazo',
                            title: 'Anti-whiplash quick connectors',
                            description: `
                                <p>With a single press, this type of "Prevost" connectors can release the internal pressure and remove them immediately in complete safety. </p>
                            `,
                            features: `
                                <ul>
                                    <li>A range of colors is available to differentiate work areas or operating conditions.</li>
                                    <li>Patented technology.</li>
                                    <li>Ergonomic design.</li>
                                    <li>Aerodynamic valve that favors air flow.</li>
                                    <li>3 years warranty against manufacturing defects.</li>
                                </ul>
                            `
                        }
                    }
                }
            },
        },
    },
    services:{//listo
        button: 'CONTACT US',
        0: {
            title: 'Rental Fleet',
            body: `
                <p>Equipment rental includes: technical support and qualified labor on all your equipment.</p>
                <p>Our equipment includes: Stationary oil-injected compressors, Oil-free compressors, Vacuum pumps, Industrial dryers.</p>
            `
        },
        1: {
            title: 'Maintenance',
            body: `
                <p>We offer preventive, corrective and predictive maintenance services for all the brands we represent.</p>
                <p>Preventive maintenance corresponds to a systematic maintenance, in which the equipment is intervened according to the manufacturer's recommendations.</p>
                <p>Corrective maintenance is performed at the time of receiving reports of any failure or defect in the equipment.</p>
                <p>Predictive maintenance is based on an analysis of the installations, which provides the necessary information to predict the future failure of a component in a piece of equipment.</p>
            `
        },
        2: {
            title: 'Policies',
            body: `
                <p>By contracting a maintenance policy you get visits according to the needs of the plant; 24/7 telephone support; labor by trained technicians; spare parts specified in the maintenance program for preventive services; frozen price of spare parts and MO; physical condition report of the equipment; among other services.</p>
            `
        },
        3: {
            title: 'Spare parts',
            body: `
                <p>Maqpower has the most complete warehouse in spare parts for compressors in Mexico. As a direct factory authorized distributor, Maqpower offers full support in 100% original "Sullair" spare parts, with 90 days warranty on spare parts against manufacturing defects.</p>
            `
        },
        4: {
            title: 'MAQ (Monitoring Air Quality)',
            body: `
                <p>Obtain a complete study of the compressed air system's generation and distribution process, in order to identify opportunities and potential energy savings to improve air quality and achieve ISO 8573-1 compliance.</p>
            `,
        },
        5: {
            title: 'Energy Measurement',
            body: `
                <p>Energy metering involves a review of the entire system, from the supply and distribution side, to the point of use.</p>
                <p>Data logging is used to document system performance to clearly identify opportunities for improvement and provide unbiased recommendations.</p>
                <p>The reports are based on studies presented by the Compressed Air and Gas Institute (CAGI), which is the body that regulates all engineering information from compressed air system manufacturers.</p>
                <p>Once the basic information is obtained, and with the help of the study, it can be determined how the compressor can be adapted to the needs of the plant, knowing the capacity control it uses.</p>
                <p>MAQPOWER has the only agent in Mexico certified by the US Department of Energy and the Compressed Air Challenge as: AIR MASTER + SPECIALIST.</p>
            `
        },
        6: {
            title: 'Courses',
            body: `
                <p>Our courses are aimed at customers and users, as well as process engineers, operations, maintenance technicians and designers involved in the selection, installation, maintenance and inspection of industrial compressors.</p>
                <p><span>In Company Courses.</span></p>
                <p><span>Practical Courses.</span></p>
                <p><span>Virtual courses.</span></p>
                <p>With an average duration of 8 hours, and with the possibility of endorsing the course with a diploma of attendance or DC-3.</p>
            `
        },
        7: {
            title: 'Compressor unit repair',
            body: `
                <p>It is important to have a timely diagnosis done by qualified personnel. This can be included and applied during the predictive maintenance service, which is performed every 8,000 hrs or 1 year, whichever comes first.</p>
                <p>Thanks to this diagnosis, you can keep track and more easily detect any abnormal noise in the compressor unit, in ad<dition to identifying metal particles or other contaminants in the result of the oil sample.
            `
        },
        after:{
            title: 'MAQ (Monitoring Air Quality)',
            body: `
                <p>With this service you get a complete study of the process of generation and distribution of the compressed air system, in order to detect opportunities and possible energy savings.</p>
                <p>Monitoring: know the current consumption of the compressed air system over a period of time.</p>
                <p>AIR: The amount of air generated must be adequate to meet the needs of your infrastructure, control airflow and air leakage.</p>
                <p>Quality: get a complete report of compressed air parameters in real time.</p>
                <p>In conclusion, it documents and interprets parameters in order to identify recommendations to improve quality and achieve ISO 8573-1 compliance.</p>
            `,
            button: 'CONTACT US',
        }
    },
    engineering:{
        eng: {
            0: {
                title: 'Engineering Consulting',
                body: `
                    <p>With highly trained engineers we can advise you on compressed air systems looking for the most efficient operation. If your current need is develop a new compressed air system, we can help you throughout all the project, from equipment selection and even turnkey project covering all your needs.</p>
                `
            },
        },
        after:{
            title: 'MAQ (Monitoring Air Quality)',
            body: `
                <p>With this service you get a complete study of the process of generation and distribution of the compressed air system, in order to detect opportunities and possible energy savings.</p>
                <p>Monitoring: know the current consumption of the compressed air system over a period of time.</p>
                <p>AIR: The amount of air generated must be adequate to meet the needs of your infrastructure, control airflow and air leakage.</p>
                <p>Quality: get a complete report of compressed air parameters in real time.</p>
                <p>In conclusion, it documents and interprets parameters in order to identify recommendations to improve quality and achieve ISO 8573-1 compliance.</p>
            `,
            button: 'CONTACT US',
        }
    },
    blog:{//listo (falta checar los botones)
        title: 'Blogs',
        button: 'MORE'
    },
    thankyou:{ //listo
        title: 'Providing solutions for the industry since 1981.',
    },
    thankyourh:{ //listo
        title: '¡Gracias por tu interés en colaborar con nosotros!',
        description: 'Hemos recibido tu información. La revisaremos y nos pondremos en contacto contigo a la brevedad posible. '
    },
    privacy: {
        title: 'Comprehensive privacy notice',
        body: `
            <p>Compresores Maqpower SA de CV, with address at Av. Aviación # 5051 Int-21, Colonia San Juan Ocotan, CP45019 Zapopan, Jalisco. and internet portal www.maqpower.com.mx, is responsible for the use and protection of your personal data, and in this regard, we inform you of the following:</p>
            <h3>For what purposes will we use your personal data?</h3>
            <p>The personal data that we collect from you, we will use for the following purposes that are necessary for the service you request:</p>
            <p>Response to messages from the contact form, Provision of any requested service</p>
            <h3>What personal data will we use for these purposes?</h3>
            <p>To carry out the purposes described in this privacy notice, we will use the following personal data:</p>
            <p>Identification and contact data, Employment data, Academic data</p>
            <h3>How can you access, rectify or cancel your personal data, or oppose it´s use or exercise the revocation of consent?</h3>
            <p>You have the right to know what personal data we have about you, what we use it for and the conditions of use that we give it (Access). Likewise, it is your right to request the correction of your personal information if it is outdated, inaccurate or incomplete (Rectification); that we delete it from our records or databases when it considers that it is not being used properly (Cancellation); as well as oppose the use of your personal data for specific purposes (Opposition). These rights are known as ARCO rights.</p>
            <p>To exercise any of the ARCO rights, you must send a request via email to mercadotecnia@maqpower.com.mx and it must contain:</p>
            <p style="margin: 0;">Full name of the owner.</p>
            <p style="margin: 0;">Home.</p>
            <p style="margin: 0;">Telephone.</p>
            <p style="margin: 0;">Email used on this website.</p>
            <p style="margin: 0;">Copy of an official identification attached.</p>
            <p style="margin: 0;">Subject "ARCO Rights"</p>
            <p>Description of the object of the writing, which can be, but not limited to, the following: Revocation of consent to process your personal data; and / or Notification of the improper use of the processing of your personal data; and / or Exercise your ARCO Rights, with a clear and precise description of the data to Access, Rectify, Cancel or Oppose. In the event of Rectification of personal data, you must indicate the exact modification and attach the supporting documentation; It is important in the event of revocation of consent, that you bear in mind that not in all cases we will be able to respond to your request or terminate the use immediately, since it is possible that due to some legal obligation we will need to continue treating your personal data.</p>
            <p>Likewise, you should consider that for certain purposes, the revocation of your consent will imply that we cannot continue to provide the service you requested, or the conclusion of your relationship with us.</p>
            <h3>In how many days will we respond to your request?</h3>
            <p>10 days</p>
            <p style="margin: 0;">d) By what means will we communicate the response to your request?</p>
            <p style="margin: 0;">To the same email from where the request was sent.</p>
            <p>The use of tracking technologies on our internet portal</p>
            <p>We inform you that on our website we use cookies, web beacons or other technologies, through which it is possible to monitor your behavior as an internet user, as well as provide you with a better service and experience when browsing our page. The personal data that we obtain from these tracking technologies are the following:</p>
            <p>Session identifiers, username and passwords, User's preferred language, Region in which the user is located, Advertising reviewed by a user</p>
            <p>These cookies, web beacons and other technologies can be disabled. To find out how to do this, consult the help menu of your browser. Please note that if cookies are disabled, you may not be able to access certain personalized features on our website.</p>
            <h3>How can you know the changes in this privacy notice?</h3>
            <p>This privacy notice may undergo modifications, changes or updates derived from new legal requirements; of our own needs for the products or services we offer; of our privacy practices; Changes in our business model, or other causes. We are committed to keeping this privacy notice updated on the changes that it may undergo and you can always consult the updates that exist on the website www.maqpower.com.mx.</p>
            <p>Last update of this privacy notice: 12/20/2021</p>
        `
    },
    terms: {
        title: 'Terms and Conditions',
        body: `
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum. Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum. Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum. Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum. Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum.</p>
        `
    },
    rh: {
        description: {
            title: 'Estamos creciendo y buscando al mejor talento.',
            body: `
                <p>En Compresores Maqpower buscamos a aquellos a los que les gusta superar retos; personas talentosas que se enfocan en el detalle, saben priorizar y se apasionan por encontrar soluciones.</p>
            `,
        },
        stats: {
            description: `
                Estamos aquí no solo para brindar las mejores soluciones de aire comprimido para la industria, sino también para agregar valor, generar confianza y estar a la vanguardia en nuestros procesos. Todo ello con personal altamente capacitado, basados en la filosofía de los accionistas y fomentando el crecimiento de energías limpias.
            `,
            0: {
                number: '9',
                title: 'sucursales'
            },
            1: {
                number: '100',
                title: 'empleados'
            },
            2: {
                number: '40+',
                title: 'años sirviendo con integridad'
            },
        },
        contact:{
            form: {
                name: 'Nombre',
                email: 'Correo Electrónico',
                phone: 'Teléfono',
                age: 'Edad',
                scholarship: {
                    title: 'Nivel de Escolaridad',
                    0: 'Primaria',
                    1: 'Secundaria',
                    2: 'Bachillerato',
                    3: 'Universidad',
                    4: 'Postgrado',
                    5: 'Doctorado',
                    6: 'Otro'
                },
                area: {
                    title: 'Área de interés',
                    0: 'Ingeniería',
                    1: 'Contabilidad / Administración',
                    2: 'Mantenimiento',
                    3: 'RRHH',
                    4: 'Comercial',
                    5: 'Almacén',
                    6: 'Atención a Clientes',
                },
                dispo: {
                    title: 'Disponibilidad',
                    0: 'Medio tiempo',
                    1: 'Tiempo completo',
                    2: 'Practicante',
                    3: 'Freelance',
                },
                exp: 'Experiencia',
                hability: 'Habilidades',
                submit: 'Enviar'
            },
            file: {
                selected: 'Seleccionar archivo',
                type: 'Adjuntar CV en formato .pdf, .doc o .docx',
                status: 'No se eligió archivo',
            }
        }
    },
    footer:{
        copy: 'Maqpower 2023. All rights reserved.',
        job: 'Job pool',
        notice: 'Privacy Notice',
        terms: 'Terms and Conditions',
        certification: {
            text: 'Certification in process'
        }
    }
}