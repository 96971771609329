export default {
    header:{//listo
        links: {
            0: 'Inicio',
            1: 'Nosotros',
            2: 'Productos',
            3: 'Servicios',
            4: 'Ingeniería',
            5: 'Blog',
            6: 'Contacto',
        },
        lang:{
            spanish: 'ES',
            english: 'EN'
        }
    },
    home: { //listo (falta checar botones) 
        hero: {//listo
            title: 'Brindando soluciones para la industria desde 1981',
            button: 'CONTÁCTANOS',
        },
        us: {//listo
            title: 'Nosotros',
            body: `
                <p>Somos una empresa mexicana con más de 40 años de experiencia, especializada en brindar soluciones en sistemas de aire comprimido para la industria en general.</p>
                <p>Distribuidores directos de fábrica de empresas como <span>“Sullair”</span>, <span>“Prevost”</span>, <span>“Lone Star"</span>, <span>"Holtec Gas System"</span> y <span>“Pricepump"</span>.</p>
                <p>Contamos con el almacén más completo de la república y el stock más amplio de partes de repuesto originales <span>“Sullair”</span>.</p>
            `,
            button: 'CONTÁCTANOS'
        },
        award: {
            title: 'Recibimos el premio como:',
            description: {
                1: '"Mejor Distribuidor de Latinoamérica en Compresores Libres de Aceite"',
                2: 'en los Annual Awards Ceremony 2023 de Sullair.'
            }
        },
        products:{//listo
            title: 'Productos',
            cards: {
                0: 'Sistemas de bombeo',
                1: 'Bombas de vacío',
                2: 'Sistemas de tratamiento de aire',
                3: 'Generadores de nitrógeno',
                4: 'Limpieza industrial',
                5: 'Sopladores',
                6: 'Sistemas de aire comprimido',
                7: 'Redes de distribución de aire comprimido'
            }
        },
        services: {//listo (hay que añadir lo que falte)
            title: 'Servicios',
            subtitle: 'Contamos con cursos, servicios de asesoría, distribución, renta y mantenimiento de sistemas de aire comprimido.',
            icons:{
                0:{
                    title: 'Renta',
                    description: 'Con el propósito de brindar respaldo y conscientes de la importancia del aire comprimido en sus procesos, ponemos a su disposición una flotilla de equipos en renta.'
                },
                1: {
                    title: 'Mantenimiento',
                    description: 'Ofrecemos el servicio de mantenimiento de carácter preventivo, correctivo y predictivo. Esto no se reserva para optimizar los sistemas “Sullair”, sino que es posible ofrecer también el servicio de reparación de unidades de compresión a otros equipos.'
                },
                2: {
                    title: 'Pólizas',
                    description: 'Una póliza es un plan preventivo y seguro diseñado para lograr que los sistemas de aire comprimido estén en su eficiencia óptima, lo que lleva a minimizar los paros ocasionados por descomposturas mayores, extender la vida de los equipos, e incluso alcanzar un mejor consumo energético.'
                },
                3: {
                    title: 'Refacciones',
                    description: 'Refacciones 100% Originales, las cuales vienen desde fábrica. Contamos con stock en todas nuestras sucursales logrando reducir tiempos de entrega.'
                },
                4: {
                    title: 'MAQ (Monitoring Air Quality)',
                    description: 'MAQ (Monitoring Air Quality) es un servicio de monitoreo, en el que se obtiene un estudio completo del proceso de generación y distribución del sistema de aire comprimido.'
                },
                5: {
                    title: 'Medición Energética',
                    description: 'La medición energética es un reporte que detalla, por medio de gráficas, el consumo energético de sus equipos de aire comprimido en tiempo real.'
                },
                6: {
                    title: 'Cursos',
                    description: 'Con nuestros cursos se adquiere un conocimiento teórico-práctico para la adecuada selección, operación, mantenimiento e inspección de compresores. '
                },
                7: {
                    title: 'Reparación de unidades de compresión',
                    description: 'Contamos con personal capacitado, talleres y herramientas especiales, para poder realizar el diagnóstico y mantenimiento necesarios a unidades de compresión.'
                }
            }
        },
        support:{//listo (no hay check de los botones)
            title: 'Mantenimiento',
            body: `Ofrecemos el servicio de mantenimiento de carácter preventivo, correctivo y predictivo. Esto no se reserva para optimizar los sistemas “Sullair”, sino que es posible ofrecer también el servicio de reparación de unidades de compresión a otros equipos.`,
            button: 'LEER MÁS',
        },
        blog:{//listo (no hay check de los botones)
            title: 'Blog',
            cardButton: 'LEER MÁS',
            button: 'LEER MÁS'
        },
        contact:{//listo
            title: 'CONTACTO',
            form:{
                name: 'Nombre',
                email: 'Correo electrónico',
                phone: 'Teléfono',
                message: 'Mensaje',
                submit: 'ENVIAR'
            },
            service:{
                title: 'Servicio 24/7',
                description: `
                    <p>Nuestro personal capacitado se encuentra disponible las 24 horas del día, los 7 días de la semana, para atenderte ante cualquier emergencia en el siguiente número: <a href="tel:+52 3334403133" title="Servicio 24/7">+52 (33) 3440-3133.</a></p>
                `
            }
        },
        offices:{//listo
            title: 'Sucursales',
            body: `
                <p>Nuestras instalaciones se encuentran en Guadalajara, Monterrey, Hermosillo, Durango, Mexicali, Tijuana, Toluca, Puebla y Los Ángeles.</p>
            `,
            cta: 'Escríbenos a:'
        },
    },
    aboutUs:{ //listo
        about:{//listo
            title: 'Acerca de',
            body: `
                <p>De la mano de nuestros proveedores ofrecemos soluciones integrales para lograr que los sistemas de aire comprimido de nuestros clientes se encuentren en su eficiencia óptima. Contamos también con redes de distribución para aire comprimido y complementamos nuestros productos con generadores de nitrógeno, turbo sopladores, limpieza industrial y procesos térmicos. </p>
            `,
            button: 'CONTÁCTANOS',
        },
        mission: {//listo
            0: {
                title: 'Misión',
                description: 'Proporcionamos soluciones integrales en aire comprimido para la industria. Fomentando el crecimiento de energías limpias, agregando valor, generando confianza y estando a la vanguardia en nuestros procesos; con personal altamente capacitado, y comprometido con la filosofía de los accionistas.'
            },
            1: {
                title: 'Visión',
                description: 'Nuestra visión 2025 consiste en “lograr que los sistemas de aire comprimido de todos nuestros clientes estén en su eficiencia óptima mientras se robustece la estructura de la empresa.”'
            },
            2: {
                title: 'Valores',
                description: 'Trabajo en equipo, Responsabilidad, Proactividad,  Integridad, Pasión, Lealtad y la Calidad.'
            }
        },
        history: { //listo
            title: 'Historia',
            description: 'Compresores Maqpower, siendo una empresa familiar mexicana, inicia operaciones en 1981 y para el 2020 ya contaban con 9 sucursales y la distribución oficial de 6 marcas.',
            years: {
                0: 'Compresores Maqpower, siendo una empresa familiar mexicana, inicia operaciones con el nombre de Compresores Perforadoras y Componentes Hidráulicos Maqpower, S.A.',
                1: 'Se firma la distribución exclusiva en la zona pacífico y norte de México con SULLAIR® CORPORATION. En el mismo año la empresa cambia de nombre a Compresores Maqpower® S.A. de C.V.',
                2: 'Se abre la sucursal Maqpower Hermosillo.',
                3: 'Se abre la sucursal Maqpower Tijuana.',
                4: 'Sucesión de director general de la empresa y en el mismo año se abre la sucursal Maqpower Mexicali y Torreón',
                5: 'La oficina matriz inaugura nuevas instalaciones en el Parque Industrial City Park en Zapopan, Jalisco.',
                6: 'Se obtiene distribución de la marca de bombas centrífugas y de diafragma Price Pump® para todo México.',
                7: 'La empresa Compresores Multiflo S.A. de C.V. se posiciona en la ciudad de León, Guanajuato para atender a la zona del Bajío, y firma distribución con Compresores Maqpower® S.A. de C.V.',
                8: 'Comenzamos venta de equipos de limpieza industrial Sioux® Corporation.',
                9: 'El primer agente en México certificado por parte del D.O.E. (Department of Energy, USA Government), como Qualified AirMaster + Compressed Air Specialist y se incursiona en el mercado de los compresores centrífugos para todo México.',
                10: 'Se renueva el logotipo y la imagen de Maqpower®',
                11: 'Se inaugura la sucursal de Maqpower Toluca.',
                12: 'Hitachi adquiere Sullair y se consolida mucho más la presencia de ambas marcas de compresores en México. Se establece la VISIÓN 2025 con una nueva sucesión. Maqpower obtiene la distribución del territorio de Los Ángeles, California, USA.',
                13: 'Se adquieren las distribución de las líneas de producto de Lone Star Blower con turbo sopladores industriales, Prevost con tubería de aluminio y toda la conectividad hasta el punto de uso, Holtec con generadores de nitrógeno de PSA y de membrana.',
                14: 'Se inaugura la sucursal Maqpower Durango.',
                15: 'Maqpower cumple 40 años de operaciones.',
            }
        },
        certifications: {
            title: 'Certificaciones de nuestros proveedores'
        },
    },
    products: {//listo
        contact: {
            button: 'CONTÁCTANOS',
        },
        notfound: {
            title: '404 not found'
        },
        tabs: {
            description: 'Descripción',
            features: 'Características'
        },
        types:{//listo
            title: 'Productos',
            categories: {
                0: { //Sistemas de bombeo
                    id: 'sistemas-de-bombeo',
                    title: 'Sistemas de bombeo',
                    description: `
                        <p>Nuestros sistemas de bombeo “Price Pump” cuentan con una gran variedad de bombas centrífugas y de diafragma, las cuales son personalizadas de acuerdo a las necesidades de tu industria.</p>
                    `,
                    0: {//Bombas de diafragma
                        title:'Bombas de diafragma',
                        0: {
                            id: 'bombas-de-diafragma',
                            title: 'Bombas de diafragma',
                            description: `
                                <p>Las bombas de diafragma “Price Pump” consisten en equipos personalizados para poder desplazar líquidos químicos o de densidades altas.</p>
                                <p>Su operación es por medio de aire comprimido, lo que ayuda a evitar riesgos por calentamiento y las convierte en un equipo seguro.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Metálica: aluminio, acero inoxidable, bronce o hierro fundido.</li>
                                    <li>No metálico: cpvc, pvdf, polipropileno.</li>
                                    <li>No requiere instalación eléctrica.</li>
                                    <li>Alimentación neumática.</li>
                                    <li>No hay riesgo de choque eléctrico.</li>
                                    <li>Ideal para manejar sustancias de alta viscosidad.</li>
                                    <li>Fácil de transportar.</li>
                                    <li>Mantenimiento mínimo.</li>
                                    <li>Operación silenciosa.</li>
                                    <li>Un año de garantía.</li>
                                </ul>
                            `,
                        },
                    },
                    1: {//Bombas centrífugas
                        title: 'Bombas centrífugas',
                        0:{
                            id: 'bombas-centrifugas',
                            title: 'Bombas centrífugas',
                            description: `
                                <p>Las bombas centrífugas “Price Pump” consisten en equipos personalizados para aplicaciones químicas y sistemas especiales.</p>
                                <p>Existen en diferentes materiales como plástico, hierro fundido, acero inoxidable y bronce.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Metálica: aluminio, acero inoxidable, bronce o hierro fundido.</li>
                                    <li>No metálica: cpvc y pvdf.</li>
                                    <li>Sello mecánico de materiales especiales para diferentes aplicaciones y temperaturas.</li>
                                    <li>Uno o varios impulsores.</li>
                                    <li>Motor según lo requiera la aplicación (odp, tefc, xp).</li>
                                    <li>Partes de larga durabilidad.</li>
                                    <li>Un año de garantía</li>
                                </ul>
                            `,
                        },
                    }
                },
                1: {//Bombas de vacio
                    id: 'bombas-de-vacio',
                    title: 'Bombas de vacío',
                    description: `
                        <p>Las bombas de vacío “Sullair” cuentan con un sistema de tornillos asimétricos de desplazamiento positivo, inundados en aceite, enfriados por aire, y de alta eficiencia volumétrica y constante vacío.</p>
                        <p>Gracias a su diseño robusto les permite realizar aplicaciones industriales, operar 24/7, de 5 hp a 150 hp.</p>
                    `,
                    0: {
                        title: 'Bombas de vacío',
                        0: {//Bombas de vacío
                            id: 'bombas-de-vacio',
                            title: 'Bombas de vacío',
                            description: `
                                <p>Las bombas de vacío “Sullair” cuentan con un sistema de tornillos asimétricos de desplazamiento positivo, inundados en aceite, enfriados por aire, y de alta eficiencia volumétrica y constante vacío.</p>
                                <p>Gracias a su diseño robusto les permite realizar aplicaciones industriales, operar 24/7, de 5 hp a 150 hp.</p>
                            `,
                            features: `
                                <ul>
                                    <li>No necesitan cimentación.</li>
                                    <li>No vibran.</li>
                                    <li>Baja velocidad de tornillos y motor.</li>
                                    <li>Bajo mantenimiento.</li>
                                    <li>Los rotores no se tocan entre sí.</li>
                                    <li>Tipo paquete listo para arranque.</li>
                                    <li>Juego completo de indicadores para operación y mantenimiento. Servicio y refacciones local. </li>
                                    <li>Garantía de 2 años en el elemento compresor y motor, un año en los demás componentes.</li>
                                </ul>
                            `
                        },
                    }
                },
                2: {//Sistemas e tratamiento de aire
                    id: 'sistemas-de-tratamiento-de-aire',
                    title: 'Sistemas de tratamiento de aire',
                    description: `
                        <p>Contamos con diferentes tipos de sistemas de tratamiento de aire “Sullair”, los cuales consisten en secadores refrigerativos, tanto cíclicos como no cíclicos; secadores desecantes y filtros coalescentes.</p>
                    `,
                    0 :{//Secadores refrigerativos
                        title: 'Secadores refrigerativos cíclicos',
                        0: {//Secadores refrigerativos cíclicos
                            id: 'secadores-refrigerativos-ciclicos',
                            title: 'Secadores refrigerativos cíclicos',
                            description: `
                                <p>Ideales para operaciones con caudal variable, ya que utilizan válvulas solenoides que se cierran durante los períodos de baja demanda atrapando refrigerante en el intercambiador de calor, el cual se encuentra completamente aislado, y actúan como masa térmica; de manera que una vez que la masa se enfría a una temperatura predeterminada, el secador queda en reposo, y enciende una vez que aumenta el punto de rocío más de lo permitido, propiciando ahorros de energía en baja demanda.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Se encuentran en el rango de los 30 a los 6,000 scfm.</li>
                                    <li>Su construcción inteligente no solo permite la más alta funcionalidad, sino también una operación confiable y de bajo costo.</li>
                                    <li>Presión máxima de trabajo: 200 psi.</li>
                                    <li>Temperatura máxima de operación: 50ºC.</li>
                                    <li>Elimina el 99.2% de la humedad del aire.</li>
                                    <li>Trampa temporizada para condensados.</li>
                                    <li>Conexiones: desde  ½” hasta 8”</li>
                                </ul>
                            `,
                        },
                        1: {//Secadores refrigerativos no cíclicos
                            id: 'secadores-refrigerativos-no-ciclicos',
                            title: 'Secadores refrigerativos no cíclicos',
                            description: `
                                <p>Ideales para operar de manera continua con una carga fija debido a que mantienen un consumo de energía constante.</p>
                                <p>Por otro lado, utilizan una derivación de gas caliente para mantener un punto de rocío estable en condiciones típicas de operación, de manera que se pueda controlar la cantidad de refrigerante que circula por el secador.</p>
                                <p>Finalmente, cuentan con un intercambiador de calor con diseño vertical, obteniendo un flujo de condensados descendente, es decir, manteniéndolos en el punto más bajo para su posterior remoción.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Dos años de garantía tapa a tapa.</li>
                                    <li>Se encuentran en el rango de los 10 a los 10,000 scfm.</li>
                                    <li>Presión máxima de trabajo: 200 psi.</li>
                                    <li>Temperatura máxima de operación: 50ºC.</li>
                                    <li>Elimina el 99.2% de la humedad del aire.</li>
                                    <li>Trampa temporizada o 0 perdidas para condensados.</li>
                                    <li>Conexiones desde ½” a 10”.</li>
                                </ul>
                            `,
                        },
                        2: {//Secadores regenerativos
                            id: 'secadores-regenerativos',
                            title: 'Secadores regenerativos',
                            description: `
                                <p>Este tipo de secadores “Sullair” son ideales para aplicaciones en las que se requieran puntos de rocío extremadamente bajos (-40ºF / -40ºC), “Sullair” ofrece un sistema confiable para asegurar la calidad de aire (limpio y seco) para las aplicaciones más críticas como la industria alimenticia, farmacéutica o electrónica.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Soporte de acero inoxidable para soportar la alúmina y una malla difusora para mejor distribución del aire.</li>
                                    <li>Circuito de reactivación totalmente automático de dos torres.</li>
                                    <li>Válvula seccionadora de entrada tipo mariposa, de alto desempeño y cuerpo de acero.</li>
                                    <li>Certificaciones eléctricas UL/CU.</li>
                                    <li>Gabinete eléctrico NEMA 12, estándar</li>
                                    <li>Protegido con una textura alta en sólidos, de acrílico poliuretano resistente a la abrasión y al impacto.</li>
                                    <li>Garantía de un año completo por la operación del secador.</li>
                                    <li>Torres construidas con acero al carbón, de acuerdo a las normas ASME.</li>
                                    <li>Incluyen válvulas de alivio y tapones para fácil acceso para llenado de alúmina.</li>
                                </ul>
                            `
                        },
                    },
                    1: { //Filtros coalescentes
                        title: 'Filtros coalescentes',
                        0:{//Filtros coalescentes
                            id: 'filtros-coalescentes',
                            title: 'Filtros coalescentes',
                            description: `
                                <p>Este tipo de filtros “Sullair” ayudan a remover el porcentaje de la humedad en estado líquido y la neblina de aceite en la corriente de aire comprimido, ya que están diseñados para cumplir con los requisitos necesarios para proporcionar el nivel adecuado de aire comprimido para cada aplicación.</p>
                                <p>Además, utilizan tecnología aeroespacial, ya que “Sullair” ha optimizado la ruta del flujo de aire a través de la carcasa metálica y el elemento interno reduciendo significativamente las turbulencias y pérdidas de presión.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Presión máxima de trabajo: 232 psi.</li>
                                    <li>Temperatura máxima de operación: 50ºC.</li>
                                    <li>Filtro FXF que retiene partículas de hasta 1 micrón y 0.5ppm de arrastre de lubricante.</li>
                                    <li>Filtro FXH que retiene partículas de hasta 0.01 micrón y 0.01ppm de arrastre de lubricante.</li>
                                    <li>Filtro FXC que retiene partículas de hasta 0.01 micrón y 0.003ppm de arrastre de lubricante.</li>
                                </ul>
                            `,
                        },
                    }
                },
                3: { //Generadores de nitrogeno
                    id: 'generadores-de-nitrogeno',
                    title: 'Generadores de nitrógeno',
                    description: `
                        <p>Contamos con generadores de nitrógeno tipo PSA y tipo membrana, ambos de la marca “Holtec Gas Systems”.</p>
                        <p>En el caso de la tecnología PSA, trabajan con el concepto de adsorción, proceso por el cual las moléculas se adhieren temporalmente a la superficie de los materiales con los que están en contacto. </p>
                        <p>Mientras que la tecnología tipo membrana utiliza uno o más módulos de membrana de tipo fibra hueca permeable, para separar el aire comprimido en una corriente de producto de nitrógeno a alta presión, y una corriente de desechos enriquecida con oxígeno a baja presión.</p>
                    `,
                    0: {//Generadores PSA
                        title: 'Generadores de nitrógeno tipo PSA',
                        description: `
                            <p>Los generadores de nitrógeno PSA constan de dos torres con material adsorbente también llamado tamiz molecular de carbono (CMS), el cual se produce específicamente para tener tamaños de poro similares al tamaño de una molécula de oxígeno (que en comparación con la molécula de nitrógeno relativamente más grande).</p>
                        `,
                        0: {//Generador serie “Niagara” (PSA)
                            id: 'niagara',
                            title: 'Generador serie “Niagara” (PSA)',
                            description: `
                                <p>Estos generadores de nitrógeno tipo PSA son diseñados con la flexibilidad y la capacidad para cubrir grandes demandas de nitrógeno. </p>
                                <p>Cuentan con capacidades de pureza superiores al 99.9995%, y flujos desde 9 a 1,900 scfm.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Tecnología avanzada de PSA, con purezas hasta 99.9995% (5 ppm de contenido de O2).</li>
                                    <li>Capacidades flexibles para satisfacer cualquier requerimiento.</li>
                                    <li>Posibilidad de integrar una interfaz gráfica de usuario (HMI), animada, a todo color, e incluso monitoreo remoto de usuarios, a través de cualquier dispositivo con conexión a internet (móvil, tableta, PC).</li>
                                    <li>Atención remota de problemas por personal de fábrica.</li>
                                    <li>Gráfica automática de tendencias del rendimiento (en tiempo real e histórico).</li>
                                </ul>
                            `,
                        },
                        1: {//Generador serie “Nitro Breeze” (PSA
                            id: 'nitro',
                            title: 'Generador serie “Nitro Breeze” (PSA)',
                            description: `
                                <p>Los equipos “Nitro Brezee” son sistemas PSA más pequeños, modulares y portátiles, diseñados para la operación “One-Touch”, cubriendo la mayoría de las aplicaciones industriales con demandas mínimas. Cuentan con capacidades y pureza superiores al 99.9%, y flujos desde 1.5 a 59 scfm. </p>
                            `,
                            features: `
                                <ul>
                                    <li>Entrega e instalación rápida.</li>
                                    <li>Tecnología avanzada de PSA, con pureza de hasta 99.9% (10 ppm de contenido de O2).</li>
                                    <li>Capacidades de demanda bajas a moderadas.</li>
                                    <li>Diseño modular.</li>
                                    <li>La instalación se adapta a través de puertas estándar con rosca macho de diferentes diámetros.</li>
                                    <li>Pantallas protegidas con contraseña para diferentes niveles de usuario.</li>
                                </ul>
                            `
                        }
                    },
                    1: {//Generadores Membrana
                        title: 'Generadores de nitrógeno tipo membrana',
                        description: `
                            <p>En este tipo de generadores, la presión de aire de alimentación típica es de 125 psig (8.6 barg), por lo que la operación a mayor presión aumentará el flujo del producto. </p>
                            <p>Además, la pureza típica del producto para nuestros generadores tipo membrana será del 95% al 99.5%.</p>
                        `,
                        0:{//Generador serie “Amazónica”
                            id: 'amazonica',
                            title: 'Generador serie “Amazónica”',
                            description: `
                                <p>Los generadores de serie “Amazónica” son equipos tipo membrana diseñados para cubrir grandes capacidades requeridas. Cuentan con un flujo constante, diseño molecular para cualquier requerimiento desde 9 hasta 1,900 scfm y pureza moderadas. </p>
                            `,
                            features: `
                                <ul>
                                    <li>Tecnología de membrana de flujo continuo.</li>
                                    <li>Proceso de presión constante.</li>
                                    <li>Purezas de N2 hasta 99.5% (0.5% de contenido de O2).</li>
                                    <li>Capacidades flexibles para satisfacer cualquier requerimiento.</li>
                                    <li>Fácil conectividad a sistemas de control de planta (DCS).</li>
                                    <li>Componentes mecánicos limitados para reducir la complejidad.</li>
                                </ul>
                            `
                        },
                        1:{//Generador serie “Spirit”
                            id: 'spirit',
                            title: 'Generador serie “Spirit”',
                            description: `
                                <p>“Spirit” es un equipo tipo membrana que puede contar con un diseño compacto facilitando su instalación y uso, el cual hace referencia a la flexibilidad y rentabilidad de las capacidades bajas a moderadas, suministrando solo el nitrógeno que se necesita.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Tecnología de membrana de flujo continuo.</li>
                                    <li>Mínimo espacio requerido para facilitar la integración al sistema.</li>
                                    <li>Capacidades desde 2 hasta 33 scfm. </li>
                                    <li>Purezas de N2 hasta 99.5% (0.5% de contenido de O2).</li>
                                    <li>Los diseños básicos no requieren de electricidad para su operación (una solución fácil para los requisitos de áreas peligrosas).</li>
                                </ul>
                            `
                        }
                    }
                },
                4: { //Limpieza industrial
                    id: 'limpieza-industrial',
                    title: 'Limpieza industrial',
                    description: `
                        <p>Contamos con equipos personalizados de limpieza industrial y procesos térmicos “Sioux”, todos ellos diseñados para resistir las condiciones más extremas. </p>
                    `,
                    0: {//Calentadores de solución
                        title: 'Calentadores de solución',
                        0: {//Calentadores de solución
                            id: 'calentadores-de-solucion',
                            title: 'Calentadores de solución',
                            description: `
                                <p>Los calentadores de solución “Sioux” están diseñados y fabricados con piezas 100% de acero inoxidable, lo que ayuda a evitar la corrosión al calentar soluciones ácidas. Su diseño robusto y confiable proporciona un suministro rápido y constante de solución a la temperatura requerida. Además, las unidades pueden operar de manera continua, por lo que no se necesita precalentamiento ni tiempo de recuperación.</p>
                                <p>En la minería al utilizar los calentadores de solución en el proceso de lixiviación para la extracción de metales preciosos, aumentando la velocidad de separación y reduciendo tiempos de operación.</p>
                            `,
                            features: ''
                        },
                    },
                    1: { //Calentadores de agua
                        id: 'calentadores-de-agua',
                        title: 'Calentadores de agua',
                        0: {//Calentadores de agua
                            title: 'Calentadores de agua',
                            description: `
                                <p>“Sioux” diseña y fabrica calentadores de agua para diferentes aplicaciones y necesidades desde hace más de 50 años. Los calentadores proveen un flujo y temperatura constante alcanzando hasta 37°C (100°F) en un lapso de 3 a 5 minutos (dependiendo del modelo).</p>
                                <p>Las máquinas de estos calentadores son fabricadas bajo código ASME sección IV y son registradas con el National Board of Boiler and Pressure Vessel Inspectors y también  cuentan con aprobación UL508A y CAN/CSA-C22.2 No. 14-10 asegurando que los paneles de control cumplan con los estándares UL y CSA.</p>
                            `,
                            features: {
                                0: {
                                    title: 'SERIE M',
                                    description: `
                                        <li>Diseño compacto para aplicaciones de baja capacidad.</li>
                                        <li>Generación de calor para 1M y hasta 3M de BTU/Hr.</li>
                                        <li>Voltaje requerido 230-460/3F/60Hz.</li>
                                        <li>El calentador puede operar con combustible, gas LP o gas natural.</li>
                                        <li>Cuenta con protección contra un bajo flujo de agua.</li>
                                        <li>Protección contra alta temperatura de agua.</li>
                                        <li>Manómetros y termómetros para poder monitorear la temperatura y presión del agua a la entrada y salida.</li>
                                        <li>Cedazo tipo malla para prevenir que partículas sólidas entren en el suministro de agua del calentador. </li>
                                    `
                                },
                                1: {
                                    title: 'SERIE HM',
                                    description: `
                                        <li>Diseño para aplicaciones de mayor capacidad, con control digital de temperatura.</li>
                                        <li>Generación de calor para 1.7M y hasta 5M de BTU/Hr.</li>
                                        <li>Voltaje requerido 230-460/3F/60Hz.</li>
                                        <li>El calentador puede operar con combustible, gas LP o gas natural.</li>
                                        <li>Cuenta con protección contra un bajo flujo de agua.</li>
                                        <li>Protección contra alta temperatura de agua.</li>
                                        <li>Manómetros y termómetros para poder monitorear la temperatura y presión del agua a la entrada y salida.</li>
                                        <li>Cedazo tipo malla para prevenir que partículas sólidas entren en el suministro de agua del calentador. </li>
                                    `
                                },
                                2: {
                                    title: 'Calentadores de agua híbridos',
                                    description: `
                                        <li>El calentador tipo espiral junto con el convencional de fuego directo proporcionan la mejor eficiencia disponible.</li>
                                        <li>Alcanzando temperaturas de hasta 91°C (195°F) sin producir vapor.</li>
                                        <li>Cuenta con un sistema de recirculación de agua, manteniendo una temperatura estable en el tanque de almacenamiento.</li>
                                        <li>Voltaje requerido: 230-460/3F/60Hz.</li>
                                        <li>Cuenta con protección contra un bajo flujo de agua.</li>
                                        <li>Protección contra alta temperatura de agua.</li>
                                        <li>Manómetros y termómetros para poder monitorear la temperatura y presión del agua a la entrada y salida.</li>
                                        <li>Cedazo tipo malla para prevenir que partículas sólidas entren en el suministro de agua del calentador.</li>
                                    `
                                },
                                3: {
                                    title: 'Serie HWP & PWP',
                                    description: `
                                        <li>Cuenta con el calentador serie M en conjunto con un tanque de almacenamiento para el agua el cual puede ser de gran capacidad 1,000 galones (HWP) para aplicaciones remotas con recursos limitados o para menor capacidad de almacenamiento con 300 galones (PWP).</li>
                                        <li>Generación de calor para 1M y hasta 3M de BTU/Hr.</li>
                                        <li>Voltaje requerido 230-460/3F/60Hz.</li>
                                        <li>El calentador puede operar con combustible, gas LP o gas natural.</li>
                                        <li>Cuenta con protección contra un bajo flujo de agua.</li>
                                        <li>Protección contra alta temperatura de agua.</li>
                                        <li>Manómetros y termómetros para poder monitorear la temperatura y presión del agua a la entrada y salida.</li>
                                        <li>Cedazo tipo malla para prevenir que partículas sólidas entren en el suministro de agua del calentador.</li>
                                    `
                                },
                                4: {
                                    title: 'M-415',
                                    description: `
                                        <li>Diseñado para una aplicación móvil (totalmente portátil).</li>
                                        <li>Este calentador puede ser instalado en camiones o camionetas para su transporte.</li>
                                        <li>Se cuenta con disponibilidad para conectarse a un voltaje de 12 VCD o 120 VAC.</li>
                                        <li>Sistema de protección y monitoreo para la flama.</li>
                                        <li>Cuenta con un motor TEFC de ½ hp de potencia para el calentador.</li>
                                        <li>Generación de calor para 415,000 BTU / hr. </li>
                                        <li>Calienta hasta 500 galones a 37,8 ° C (100°F) en una hora.</li>
                                        <li>Indicadores de temperatura y presión.</li>
                                    `
                                },
                                5: {
                                    title: 'All electric',
                                    description: `
                                        <li>Diseñada para aplicaciones específicas donde no puede hacerse uso de combustibles como diésel, gas LP o gas natural para calentador de agua.</li>
                                        <li>Capaz de calentar agua hasta 93°C (200°F).</li>
                                        <li>La resistencia calefactora requiere de una alimentación trifásica 480V/3F/60Hz.</li>
                                        <li>Cuenta con protección contra bajo flujo de agua y alta temperatura.</li>
                                        <li>No genera emisiones de gases contaminantes.</li>
                                    `
                                }
                            },
                        },
                        1: {//serie-m
                            id: 'serie-m',
                            title: 'SERIE M',
                            description: `
                                <p>“Sioux” diseña y fabrica calentadores de agua para diferentes aplicaciones y necesidades desde hace más de 50 años. Los calentadores proveen un flujo y temperatura constante alcanzando hasta 37°C (100°F) en un lapso de 3 a 5 minutos (dependiendo del modelo).</p>
                                <p>Las máquinas de estos calentadores son fabricadas bajo código ASME sección IV y son registradas con el National Board of Boiler and Pressure Vessel Inspectors y también  cuentan con aprobación UL508A y CAN/CSA-C22.2 No. 14-10 asegurando que los paneles de control cumplan con los estándares UL y CSA.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Diseño compacto para aplicaciones de baja capacidad.</li>
                                    <li>Generación de calor para 1M y hasta 3M de BTU/Hr.</li>
                                    <li>Voltaje requerido 230-460/3F/60Hz.</li>
                                    <li>El calentador puede operar con combustible, gas LP o gas natural.</li>
                                    <li>Cuenta con protección contra un bajo flujo de agua.</li>
                                    <li>Protección contra alta temperatura de agua.</li>
                                    <li>Manómetros y termómetros para poder monitorear la temperatura y presión del agua a la entrada y salida.</li>
                                    <li>Cedazo tipo malla para prevenir que partículas sólidas entren en el suministro de agua del calentador. </li>
                                </ul>
                            `
                        },
                        2: {//serie-hm
                            id: 'serie-hm',
                            title: 'SERIE HM',
                            description: `
                                <p>“Sioux” diseña y fabrica calentadores de agua para diferentes aplicaciones y necesidades desde hace más de 50 años. Los calentadores proveen un flujo y temperatura constante alcanzando hasta 37°C (100°F) en un lapso de 3 a 5 minutos (dependiendo del modelo).</p>
                                <p>Las máquinas de estos calentadores son fabricadas bajo código ASME sección IV y son registradas con el National Board of Boiler and Pressure Vessel Inspectors y también  cuentan con aprobación UL508A y CAN/CSA-C22.2 No. 14-10 asegurando que los paneles de control cumplan con los estándares UL y CSA.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Diseño para aplicaciones de mayor capacidad, con control digital de temperatura.</li>
                                    <li>Generación de calor para 1.7M y hasta 5M de BTU/Hr.</li>
                                    <li>Voltaje requerido 230-460/3F/60Hz.</li>
                                    <li>El calentador puede operar con combustible, gas LP o gas natural.</li>
                                    <li>Cuenta con protección contra un bajo flujo de agua.</li>
                                    <li>Protección contra alta temperatura de agua.</li>
                                    <li>Manómetros y termómetros para poder monitorear la temperatura y presión del agua a la entrada y salida.</li>
                                    <li>Cedazo tipo malla para prevenir que partículas sólidas entren en el suministro de agua del calentador. </li>
                                </ul>
                            `
                        },
                        3: {//calentadores-de-agua-hibridos
                            id: 'calentadores-de-agua-hibridos',
                            title: 'Calentadores de agua híbridos',
                            description: `
                                <p>“Sioux” diseña y fabrica calentadores de agua para diferentes aplicaciones y necesidades desde hace más de 50 años. Los calentadores proveen un flujo y temperatura constante alcanzando hasta 37°C (100°F) en un lapso de 3 a 5 minutos (dependiendo del modelo).</p>
                                <p>Las máquinas de estos calentadores son fabricadas bajo código ASME sección IV y son registradas con el National Board of Boiler and Pressure Vessel Inspectors y también  cuentan con aprobación UL508A y CAN/CSA-C22.2 No. 14-10 asegurando que los paneles de control cumplan con los estándares UL y CSA.</p>
                            `,
                            features: `
                                <ul>
                                    <li>El calentador tipo espiral junto con el convencional de fuego directo proporcionan la mejor eficiencia disponible.</li>
                                    <li>Alcanzando temperaturas de hasta 91°C (195°F) sin producir vapor.</li>
                                    <li>Cuenta con un sistema de recirculación de agua, manteniendo una temperatura estable en el tanque de almacenamiento.</li>
                                    <li>Voltaje requerido: 230-460/3F/60Hz.</li>
                                    <li>Cuenta con protección contra un bajo flujo de agua.</li>
                                    <li>Protección contra alta temperatura de agua.</li>
                                    <li>Manómetros y termómetros para poder monitorear la temperatura y presión del agua a la entrada y salida.</li>
                                    <li>Cedazo tipo malla para prevenir que partículas sólidas entren en el suministro de agua del calentador.</li>
                                </ul>
                            `,
                        },
                        4: {//serie-hwp-pwp
                            id: 'serie-hwp-pwp',
                            title: 'Serie HWP & PWP',
                            description: `
                                <p>“Sioux” diseña y fabrica calentadores de agua para diferentes aplicaciones y necesidades desde hace más de 50 años. Los calentadores proveen un flujo y temperatura constante alcanzando hasta 37°C (100°F) en un lapso de 3 a 5 minutos (dependiendo del modelo).</p>
                                <p>Las máquinas de estos calentadores son fabricadas bajo código ASME sección IV y son registradas con el National Board of Boiler and Pressure Vessel Inspectors y también  cuentan con aprobación UL508A y CAN/CSA-C22.2 No. 14-10 asegurando que los paneles de control cumplan con los estándares UL y CSA.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Cuenta con el calentador serie M en conjunto con un tanque de almacenamiento para el agua el cual puede ser de gran capacidad 1,000 galones (HWP) para aplicaciones remotas con recursos limitados o para menor capacidad de almacenamiento con 300 galones (PWP).</li>
                                    <li>Generación de calor para 1M y hasta 3M de BTU/Hr.</li>
                                    <li>Voltaje requerido 230-460/3F/60Hz.</li>
                                    <li>El calentador puede operar con combustible, gas LP o gas natural.</li>
                                    <li>Cuenta con protección contra un bajo flujo de agua.</li>
                                    <li>Protección contra alta temperatura de agua.</li>
                                    <li>Manómetros y termómetros para poder monitorear la temperatura y presión del agua a la entrada y salida.</li>
                                    <li>Cedazo tipo malla para prevenir que partículas sólidas entren en el suministro de agua del calentador.</li>
                                </ul>
                            `,
                        },
                        5: {//M-415
                            id: 'M-415',
                            title: 'M-415',
                            description: `
                                <p>“Sioux” diseña y fabrica calentadores de agua para diferentes aplicaciones y necesidades desde hace más de 50 años. Los calentadores proveen un flujo y temperatura constante alcanzando hasta 37°C (100°F) en un lapso de 3 a 5 minutos (dependiendo del modelo).</p>
                                <p>Las máquinas de estos calentadores son fabricadas bajo código ASME sección IV y son registradas con el National Board of Boiler and Pressure Vessel Inspectors y también  cuentan con aprobación UL508A y CAN/CSA-C22.2 No. 14-10 asegurando que los paneles de control cumplan con los estándares UL y CSA.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Diseñado para una aplicación móvil (totalmente portátil).</li>
                                    <li>Este calentador puede ser instalado en camiones o camionetas para su transporte.</li>
                                    <li>Se cuenta con disponibilidad para conectarse a un voltaje de 12 VCD o 120 VAC.</li>
                                    <li>Sistema de protección y monitoreo para la flama.</li>
                                    <li>Cuenta con un motor TEFC de ½ hp de potencia para el calentador.</li>
                                    <li>Generación de calor para 415,000 BTU / hr. </li>
                                    <li>Calienta hasta 500 galones a 37,8 ° C (100°F) en una hora.</li>
                                    <li>Indicadores de temperatura y presión./li>
                                </ul>
                            `,
                        },
                        6: {//all-electric
                            id: 'all-electric',
                            title: 'All electric',
                            description: `
                                <p>“Sioux” diseña y fabrica calentadores de agua para diferentes aplicaciones y necesidades desde hace más de 50 años. Los calentadores proveen un flujo y temperatura constante alcanzando hasta 37°C (100°F) en un lapso de 3 a 5 minutos (dependiendo del modelo).</p>
                                <p>Las máquinas de estos calentadores son fabricadas bajo código ASME sección IV y son registradas con el National Board of Boiler and Pressure Vessel Inspectors y también  cuentan con aprobación UL508A y CAN/CSA-C22.2 No. 14-10 asegurando que los paneles de control cumplan con los estándares UL y CSA.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Diseñada para aplicaciones específicas donde no puede hacerse uso de combustibles como diésel, gas LP o gas natural para calentador de agua.</li>
                                    <li>Capaz de calentar agua hasta 93°C (200°F).</li>
                                    <li>La resistencia calefactora requiere de una alimentación trifásica 480V/3F/60Hz.</li>
                                    <li>Cuenta con protección contra bajo flujo de agua y alta temperatura.</li>
                                    <li>No genera emisiones de gases contaminantes.</li>
                                </ul>
                            `
                        }
                    },
                    2: {//Generadores de vapor
                        id: 'generadores-de-vapor',
                        title: 'Generadores de vapor',
                        0: {//Generadores de vapor
                            title: 'Generadores de vapor',
                            description: `
                                <p>Los generadores de vapor “Sioux Steam-Flo” son una perfecta fuente de grandes cantidades de vapor a baja presión, produciéndolo en minutos. </p>
                                <p>A menudo se usan en invernaderos comerciales, viveros y aplicaciones industriales, como el calentamiento de camisas de vapor, descongelación de tuberías y válvulas, curado de concreto y calentamiento de vagones cisterna para descarga.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Proporcionan vapor continuo e ilimitado.</li>
                                    <li>Fáciles de mantener y construidos para durar.</li>
                                    <li>Unidades disponibles para aplicaciones estacionarias.</li>
                                    <li>Disponibilidad para uso sobre las unidades de remolque para aplicaciones móviles.</li>
                                    <li>Se pueden equipar con un tanque de agua opcional de manera que se logre una operación completamente autónoma.</li>
                                    <li>La caldera está registrada en la Junta Nacional de Inspectores de Calderas y Recipientes a Presión, por lo que cumple con los requisitos de CSD-1 del Código ASME y está construida según la Sección IV del código ASME.</li>
                                    <li>Cuenta con aprobación UL508A y CAN / CSA-C22.2 No. 14-10, asegurando que todo el conjunto del panel de control cumpla con los estándares UL y CSA. </li>
                                </ul>
                            `,
                        }
                    },
                    3: {//Hidrolavadoras
                        id: 'hidrolavadoras',
                        title: 'Hidrolavadoras',
                        0: {//Hidrolavadoras
                            title: 'Hidrolavadoras',
                            description: `
                                <p>Las Hidrolavadoras y limpiadoras a vapor “Sioux” consisten en equipos diseñados para resistir las condiciones más extremas. </p>
                                <p>Las lavadoras a presión calientes y frías son ideales para eliminar la suciedad. Los limpiadores a vapor son excelentes para limpiar grasas y aceites, esterilizar, desinfectar y disolver resinas.</p>
                            `,
                            features: {
                                titles: {
                                    description: 'Descripción:',
                                    features: 'Características:'
                                },
                                0: {
                                    title: 'Serie “EN”',
                                    description: `
                                        <li>La serie “EN” presenta un marco de acero industrial extra resistente, el cual está construido para entornos operativos extremadamente exigentes. Estas unidades son nuestra opción a prueba de explosión que está disponible para permitir que la unidad se use en ubicaciones peligrosas (clasificadas), las cuales, según OSHA 1910.303 (a), se pueden encontrar en hangares de aviones, plantas dispensadoras de gasolina y de almacenamiento a granel, así como plantas de proceso de acabado de pintura, instalaciones de atención médica, plantas agrícolas, puertos deportivos, astilleros y plantas de procesamiento de petróleo y productos químicos.</li>
                                    `,
                                    features: `
                                        <li>Diseñado para aplicaciones de gran demanda.</li>
                                        <li>Diseño de operación fija.</li>
                                        <li>No genera emisiones de gases contaminantes.</li>
                                        <li>Proporciona agua caliente en menos de 1 minuto y vapor en menos de 3 minutos.</li>
                                    `
                                },
                                1: {
                                    title: 'Serie “E”',
                                    description: `
                                        <li>Las unidades de la serie “E” son para mercados que requieren el mismo poder de limpieza que la serie “EN”, pero en un diseño más compacto.</li>
                                    `,
                                    features: `
                                        <li>Tienen un marco de acero industrial resistente y utilizan componentes similares a la serie “EN”.</li>
                                        <li>Son ideales para aplicaciones donde la portabilidad y el espacio son una preocupación, como las instalaciones farmacéuticas, químicas, de procesamiento de alimentos y de fabricación en general.</li>
                                    `
                                },
                                2: {
                                    title: 'Lavadoras de alta presión a combustible',
                                    description: `
                                        <p>Las lavadoras a presión de agua caliente accionadas por motor están disponibles como unidades autónomas con una lavadora a presión integrada, tanque de agua y motor de gasolina o diésel en un patín de servicio pesado o sobre el remolque de carretera.  </p>
                                        <p>Las lavadoras a presión convencionales están disponibles con un motor eléctrico o de combustión interna para la bomba de agua y un quemador accionado por combustible. Estos equipos pueden operar con agua fría, agua caliente y opción para entregar vapor. Para uso fijo o totalmente portátil en su versión con motor a combustión interna.</p>
                                    `,
                                    features: `
                                        <li>Incluyen la opción de limpieza a vapor, lavado a presión en caliente y enjuague a presión en frío en una unidad compacta. </li>
                                        <li>Son excelentes para la limpieza en ubicaciones remotas.</li>
                                        <li>Se  cuenta con la opción para montaje sobre remolque haciéndolo totalmente portátil.</li>
                                        <li>Proporciona agua caliente en menos de 1 minuto y vapor en menos de 3 minutos.</li>
                                    `
                                },
                            }
                        },
                        1: {
                            id: 'serie-en',
                            title: 'Serie “EN”',
                            description: `
                                <p>La serie “EN” presenta un marco de acero industrial extra resistente, el cual está construido para entornos operativos extremadamente exigentes. Estas unidades son nuestra opción a prueba de explosión que está disponible para permitir que la unidad se use en ubicaciones peligrosas (clasificadas), las cuales, según OSHA 1910.303 (a), se pueden encontrar en hangares de aviones, plantas dispensadoras de gasolina y de almacenamiento a granel, así como plantas de proceso de acabado de pintura, instalaciones de atención médica, plantas agrícolas, puertos deportivos, astilleros y plantas de procesamiento de petróleo y productos químicos.</p>
                            `,
                            features: `
                            <ul>
                                <li>Diseñado para aplicaciones de gran demanda.</li>
                                <li>Diseño de operación fija.</li>
                                <li>No genera emisiones de gases contaminantes.</li>
                                <li>Proporciona agua caliente en menos de 1 minuto y vapor en menos de 3 minutos.</li>
                            </ul>
                            `,
                        },
                        2: {
                            id: 'serie-e',
                            title: 'Serie “E”',
                            description: `
                                <p>Las unidades de la serie “E” son para mercados que requieren el mismo poder de limpieza que la serie “EN”, pero en un diseño más compacto.</p>
                            `,
                            features: `
                            <ul>
                                <li>Tienen un marco de acero industrial resistente y utilizan componentes similares a la serie “EN”.</li>
                                <li>Son ideales para aplicaciones donde la portabilidad y el espacio son una preocupación, como las instalaciones farmacéuticas, químicas, de procesamiento de alimentos y de fabricación en general.</li>
                            </ul>
                            `,
                        },
                        3: {
                            id: 'lavadoras-de-alta-presion-a-combustible',
                            title: 'Lavadoras de alta presión a combustible',
                            description: `
                            <p>Las lavadoras a presión de agua caliente accionadas por motor están disponibles como unidades autónomas con una lavadora a presión integrada, tanque de agua y motor de gasolina o diésel en un patín de servicio pesado o sobre el remolque de carretera.  </p>
                            <p>Las lavadoras a presión convencionales están disponibles con un motor eléctrico o de combustión interna para la bomba de agua y un quemador accionado por combustible. Estos equipos pueden operar con agua fría, agua caliente y opción para entregar vapor. Para uso fijo o totalmente portátil en su versión con motor a combustión interna.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Incluyen la opción de limpieza a vapor, lavado a presión en caliente y enjuague a presión en frío en una unidad compacta. </li>
                                    <li>Son excelentes para la limpieza en ubicaciones remotas.</li>
                                    <li>Se  cuenta con la opción para montaje sobre remolque haciéndolo totalmente portátil.</li>
                                    <li>Proporciona agua caliente en menos de 1 minuto y vapor en menos de 3 minutos.</li>
                                </ul>
                            `
                        }
                    }
                },
                5: {//Sopladores
                    id: 'sopladores',
                    title: 'Sopladores',
                    description: `
                        <p>Contamos con diferentes tipos de sopladores industriales de la marca “Lone Star Blower”. Ideales para aplicaciones como, Industria Minera, Tratamiento de Aguas y Cementeras, etc.</p>
                    `,
                    0: {
                        title: 'Sopladores',
                        0:{//Soplador con engranaje
                            id: 'soplador-con-engranaje',
                            title: 'Soplador con engranaje',
                            description: `
                                <p>Estos turbo sopladores industriales “Lone Star”, con engranaje integral, funcionan en un rango de 100 hp hasta 6,000 hp.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Alta eficiencia aerodinámica en todo el rango de flujo.</li>
                                    <li>Velocidad constante.</li>
                                    <li>Reducción al 40% del flujo de diseño a velocidad constante.</li>
                                    <li>Flujo variable mediante paletas difusoras de descarga, álabes guía de entrada.</li>
                                    <li>Baleros especiales para una larga vida útil y un mantenimiento mínimo.</li>
                                    <li>Compresión libre de aceite.</li>
                                    <li>Bajos costos de operación y mantenimiento.</li>
                                </ul>
                            `
                        },
                        1:{//Soplador multietapa “Lone Star”
                            id: 'lone-star',
                            title: 'Soplador multietapa “Lone Star”',
                            description: `
                                <p>Estos turbo sopladores industriales “Lone Star” son diseñados desde 20 hp hasta 3,000 hp, pudiendo contar con 1 y hasta 10 etapas de compresión, garantizando la máxima eficiencia.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Impulsores de gran diámetro que producen una alta velocidad para mantener la presión.</li>
                                    <li>Diseños desde 1 hasta 10 impulsores.</li>
                                    <li>Puede operar con aire y gas.</li>
                                    <li>Diseño altamente flexible para cumplir con la demanda de aire.</li>
                                    <li>20 hp - 3,000 hp, 2 - 25 PSI, 300 - 45,000 SCFM.</li>
                                    <li>Motor de acoplamiento directo: sin engranajes ni correas.</li>
                                    <li>Baja velocidad.</li>
                                    <li>Rodamiento de rodillos auto lubricados.</li>
                                    <li>Mínimo mantenimiento.</li>
                                    <li>Funcionamiento silencioso <85 dba.</li>
                                </ul>
                            `
                        },
                        2: {//Soplador sin engranaje TurboMax
                            id: 'turbomax',
                            title: 'Soplador sin engranaje TurboMax',
                            description: `
                                <p>Estos turbo sopladores industriales “Lone Star” funcionan sin engranajes, y de 20 hp a 1,000 hp. Además son compactos y de fácil instalación, ya que el controlador, la válvula de escape, el variador de frecuencia y todos los sensores están completamente integrados dentro de una caseta, todos listos para conectarse al sistema de aire comprimido.</p>
                            `,
                            features: `
                                <ul>
                                    <li>20 a 40% más de eficiencia energética, la cual ofrece menos de 3 años de retorno de inversión.</li>
                                    <li>75 a 85 dba.</li>
                                    <li>Normalmente utiliza 1/3 del espacio de las tecnologías existentes.</li>
                                    <li>No cuenta con lubricación, alineación o mantenimiento programados junto al cambio de filtro.</li>
                                    <li>Con solo agregar energía ya está listo para funcionar.</li>
                                    <li>Cuenta con motor de magneto permanente de alta velocidad, ofreciendo una eficiencia superior a cargas parciales.</li>
                                </ul>
                            `
                        }
                    }
                },
                6: {//Sistemas de aire comprimido
                    id: 'sistemas-de-aire-comprimido',
                    title: 'Sistemas de aire comprimido',
                    description: ``,
                    0:{
                        title: 'Compresores libres de aceite',
                        0: {//Compresores DSP
                            id: 'dsp',
                            title: 'Compresores DSP',
                            description: `
                                <p>La Serie DSP de “Sullair” ofrece procesos avanzados libres de aceite justo donde se necesita. Fabricados con tecnología de punta japonesa, el DSP está diseñado para suministrar aire libre de aceite de manera confiable y eficiente, cumpliendo con la ISO 8573-1 como Clase 0.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Aire libre de aceite Clase 0.</li>
                                    <li>Arrancador conectado en estrella-delta o VSD.</li>
                                    <li>Monitor de fase.</li>
                                    <li>Capacidad de operación en una altitud de hasta 9000 pies.</li>
                                    <li>Recuperador de neblinas de aceite en caja de engranes (patentado).</li>
                                    <li>Válvula de aislamiento motorizada a la descarga que evita el retorno de condensados cuando el equipo está fuera de operación.</li>
                                    <li>Los modelos variables (VSD) cuentan con un diseño patentado sin válvula de admisión.</li>
                                    <li>Diseño patentado de pre enfriador de alta eficiencia.</li>
                                </ul>
                            `
                        },
                        1: {//Compresores tipo Scroll
                            id: 'scroll',
                            title: 'Compresores tipo Scroll',
                            description: `
                                <p>Los compresores Scroll de “Sullair” se caracterizan por su operación exenta de aceite y extremadamente silenciosa, ya que su operación genera menos de 60 dB(A). Esto es gracias a que el proceso se realiza con la ayuda de dos espirales, uno con rotación orbital y otro fijo, de manera que el aire ingresa a la cámara de compresión y se reduce el volumen gradualmente, a medida que gira la espiral en órbita. El movimiento de la espiral comprime así el aire de la atmósfera,  creando aire libre de aceite y sin contaminantes. </p>
                            `,
                            features: `
                                <ul>
                                    <li>Se encuentran en rangos de 2 hp a 44 hp. </li>
                                    <li>Están diseñados con un sistema envolvente de espiral, con sello de laberinto, el cual reduce la fuga de aire mientras prolonga los intervalos de servicio. </li>
                                    <li>Entran en la gama de equipos libres de aceite, los cuales cumplen con la normativa ISO 8573-1 y con ello ofrecen aire clase 0 en su proceso de compresión ya que no requieren de aceite para operar.</li>
                                    <li>Presiones de aire de hasta 145 psig.</li>
                                    <li>Equipos multietapas para adaptar la entrega de aire a la demanda de la aplicación.</li>
                                    <li>Cabina estándar.</li>
                                    <li>Aislamiento de vibraciones mecánicas y eléctricas.</li>
                                    <li>Diseñado para ser colocado en el punto donde se necesita el aire.</li>
                                    <li>Fácil acceso para inspección y mantenimiento.</li>
                                </ul>
                            `,
                        },
                        2: {//Compresores centrífugos
                            id: 'centrifugos',
                            title: 'Compresores centrífugos',
                            description: `
                                <p>Los compresores centrífugos “Sullair” son equipos diseñados específicamente para cada requerimiento considerando flujo y presión, buscando siempre la menor potencia requerida. De diseño simple, reduciendo las piezas necesarias para mantenimiento. </p>
                            `,
                            features: `
                                <ul>
                                    <li>Se diseñan para un rango de potencia entre los 300 hp hasta 30,000 hp. </li>
                                    <li>Están disponibles con diseño multietapa.</li>
                                    <li>Combinación óptima en sus componentes, desde los impulsores fabricados en titanio o acero inoxidable hasta los baleros de cojinetes radiales con segmentos oscilantes.</li>
                                    <li>Funcionan con una alta eficiencia, lo que da como resultado un menor consumo de energía.</li>
                                    <li>Mínimo mantenimiento, debido a que muchos de los componentes tienen un muy bajo nivel de desgaste.</li>
                                </ul>
                            `
                        },
                        
                    },
                    1: {
                        title: 'Compresores con inyección de aceite',
                        0: {//Compresores Encapsulados
                            id: 'encapsulados',
                            title: 'Compresores Encapsulados',
                            description: `
                                <p>Estos compresores “Sullair” de tornillo de una etapa de compresión, cuentan con inyección de lubricante, de alta eficiencia volumétrica y constante. Funcionan con un sistema de modulación que puede reducir el consumo de energía al disminuir la entrada de aire, adaptando la descarga a la demanda de aire en planta y aligerando la carga en el motor principal. </p>
                                <p>Se surte con fluido “SULLUBE” sintético, a base de poliglycol que no contiene hidrocarburos, lo que impide formación de barnices y carbonilla, de acción prolongada, permitiendo usarse en períodos de 10,000 horas ó 1 año de operación. Los compresores de 5 hp hasta 25 hp se pueden manejar con kit completo, el cual incluye tanque, filtros, caseta acústica y todo lo que un sistema de compresión necesita para operar, manteniendo su formato compacto. </p>
                            `,
                            features: `
                                <ul>
                                    <li>Se encuentran en rangos de 5hp a 25 hp.</li>
                                    <li>Caudal permanente (eficiencia volumétrica no varía).</li>
                                    <li>Presión constante por modulación.</li>
                                    <li>No necesita cimentación, no vibra.</li>
                                    <li>Juego completo de indicadores para operación y mantenimiento.</li>
                                    <li>Dispositivos de seguridad contra anomalías en temperatura, presión o consumo de corriente eléctrica.</li>
                                    <li>Sistema de control por modulación y sistema carga-no carga, o paro y arranque automático. </li>
                                    <li>Superficie pequeña para montaje.</li>
                                </ul>
                            `
                        },
                        1:{//Compresor S-Energy
                            id: 's-energy',
                            title: 'Compresor <span>S-Energy</span>',
                            description: `
                                <p>Estos compresores de tornillo de una etapa de compresión “Sullair” se caracterizan principalmente por los controles de capacidad adecuados para cada aplicación, y cuentan con inyección de lubricante, de alta eficiencia volumétrica y constante. </p>
                                <p>Se adaptan a demandas generales de las plantas, contando con las opciones de variador de velocidad (VSD) y variador de capacidad (válvula espiral) para consumos de aire inestables. Además, esta unidad se surte con fluido “SULLUBE” sintético, a base de poliglycol que no contiene hidrocarburos, lo que impide formación de barnices y carbonilla, de acción prolongada, permitiendo usarse en períodos de 10,000 horas ó 1 año de operación.  </p>
                            `,
                            features: `
                                <ul>
                                    <li>De diseño robusto.</li>
                                    <li>Garantía integral Diamond de hasta 10 años respaldada por “Sullair”. </li>
                                    <li>Se encuentra en rangos de 25 hp a 100 hp.</li>
                                    <li>Válvula de admisión de aire de baja restricción para un mejor desempeño en cfm (m³/min).</li>
                                    <li>Diseño de separador de aire/aceite con bajo arrastre.</li>
                                    <li>Motor principal y ventilador de alta eficiencia.</li>
                                    <li>Arrancador NEMA 4 o 12 (varía según el modelo).</li>
                                    <li>Huella compacta.</li>
                                    <li>Su filtro de aire de alta eficiencia proporciona una filtración 10 veces mejor que otros filtros.</li>
                                    <li>Válvula en espiral disponible en 75 hp y 100 hp.</li>
                                    <li>Sistema de control por modulación, sistema carga-no carga y variador de velocidad opcional.</li>
                                </ul>
                            `
                        },
                        2: {//Compresores de tornillo en una etapa
                            id: 'compresores-de-tornillo-en-una-etapa',
                            title: 'Compresores de tornillo en una etapa',
                            description: `
                                <p>Los compresores de una etapa “Sullair” se caracterizan principalmente por sus controles de capacidad, adecuados para cada aplicación, y funcionan con tecnología de tornillo de una etapa de compresión, la cual cuenta con inyección de lubricante y alta eficiencia volumétrica. </p>
                                <p>Además estos compresores cuentan con sistema de enfriamiento por aire o agua según se requiera.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Se surten con fluido SULLUBE sintético. </li>
                                    <li>Se encuentran en rangos de 125 hp a 350 hp.</li>
                                    <li>Control de capacidad por modulación, válvula espiral y VSD disponibles. </li>
                                    <li>Caudal permanente (no varía la eficiencia volumétrica).</li>
                                    <li>Presión constante por control de capacidad.</li>
                                    <li>No necesita cimentación, no vibra.</li>
                                    <li>Los rotores no se tocan entre sí, debido a la baja velocidad en tornillos y motor.</li>
                                    <li>Tipo paquete listo para arranque, incluye caseta acústica.</li>
                                    <li>Juego completo de indicadores para operación y mantenimiento.</li>
                                    <li>Dispositivos de seguridad contra anomalías en temperatura, presión o consumo de corriente eléctrica.</li>
                                    <li>Controlador STS a color con pantalla táctil de 10”.</li>
                                    <li>Sistema de monitoreo remoto AirLinx.</li>
                                    <li>Arrancador estrella-delta NEMA 4 como estándar.</li>
                                    <li>Dren 0 pérdida de aire para ahorrar energía.</li>
                                </ul>
                            `
                        },
                        3: {//Compresores de tornillo en dos etapas
                            id: 'compresores-de-tornillo-en-dos-etapas',
                            title: 'Compresores de tornillo en dos etapas',
                            description: `
                                <p>Al combinar la compresión de dos etapas con una válvula espiral “Sullair”, se obtiene un rendimiento energético incomparable a plena carga o cargas parciales, por lo que el volumen de la unidad compresora varía para ajustarse a la demanda de aire mediante la apertura o el cierre progresivo de los puertos de derivación en la unidad. De esta manera, la capacidad se ajusta a la demanda del sistema, reduciendo así el tiempo cíclico, y prolongando así la vida útil del componente. En otras palabras, el equipo de dos etapas implementa la tecnología a su favor, obteniendo más aire por hp y el mejor control de capacidad para cargas parciales.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Se surten con fluido SULLUBE sintético. </li>
                                    <li>Se encuentran en rangos de 150 hp a 600 hp. </li>
                                    <li>Caudal permanente (eficiencia volumétrica no varía).</li>
                                    <li>Control de capacidad por modulación, válvula espiral y VSD disponibles. </li>
                                    <li>Presión constante por control capacidad.</li>
                                    <li>No necesitan cimentación, no vibra.</li>
                                    <li>Los rotores no se tocan entre sí, debido a la baja velocidad en tornillos y motor.</li>
                                    <liTipo paquete, listos para arranque.</li>
                                    <li>Juego completo de indicadores para operación y mantenimiento.</li>
                                    <li>Dispositivos de seguridad contra anomalías en temperatura, presión o consumo de corriente eléctrica.</li>
                                </ul>
                            `
                        }
                    }
                },
                7:{//Redes de distribución de aire comprimido
                    id: 'redes-de-distribucion-de-aire-comprimido',
                    title: 'Redes de distribución de aire comprimido',
                    description: `
                        <p>Nuestras redes de distribución “Prevost” para aire comprimido son diseñadas y fabricadas 100% con aluminio. Además, la aleación del aluminio utilizada y combinada con pintura epoxi externa y tratamiento interior, protege los tubos contra los riesgos de oxidación y corrosión que pueden llegar a traer problemas a largo plazo. </p>
                    `,
                    0: {//Redes de distribución de aire comprimido
                        title: 'Redes de distribución de aire comprimido',
                        0: {//Tuberías de aluminio
                            id: 'tuberias-de-aluminio',
                            title: 'Tuberías de aluminio',
                            description: `
                                <p>En este tipo de tuberías “Prevost”, se recomienda su instalación a una altura mínima de 2,5 m del suelo y considerando un circuito cerrado principal para mantener una presión estable en cada punto de uso.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Tubería 100% de aluminio.</li>
                                    <li>Fácil instalación.</li>
                                    <li>Excelente coeficiente de fricción. </li>
                                    <li>Material compacto, ligero y resistente.</li>
                                    <li>Disponible en diámetros de ½” hasta 4”</li>
                                </ul>
                            `,
                        },
                        1: {//Sistemas de filtración FRL’s
                            id: 'sistemas-de-filtracion-frls',
                            title: 'Sistemas de filtración FRL’s',
                            description: `
                                <p>Los sistemas de filtración FRL´s “Prevost”, consisten en un filtro o conjunto de filtros diseñados para retener sólidos, eliminar cierta cantidad de líquidos, regular la presión del aire comprimido y enviar el aceite necesario para lubricar internamente las herramientas neumáticas. </p>
                            `,
                            features: `
                                <ul>
                                    <li>Disponibles en conexiones de ¼” y hasta 1”.</li>
                                    <li>Capacidad de filtrado desde 40 micrones hasta 0.01 micrones.</li>
                                    <li>Disponibles para adquisición individual o en conjunto.</li>
                                    <li>Placas de montaje opcionales.</li>
                                </ul>
                            `
                        },
                        2: {//Conexiones de aluminio
                            id: 'conexiones-de-aluminio',
                            title: 'Conexiones de aluminio',
                            description: `
                                <p>Las conexiones “Prevost” están fabricadas 100% en aluminio, incluyen un anillo dentado de acero inoxidable, empaque tipo junta doble lo cual permite una fijación perfecta evitando deslices o fugas de aire.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Compactas.</li>
                                    <li>Ligeras.</li>
                                    <li>Resistentes.</li>
                                    <li>Disponibles en diámetros desde ½” hasta 4”</li>
                                </ul>
                            `
                        },
                        3: {//Carretes retráctiles
                            id: 'carretes-retractiles',
                            title: 'Carretes retráctiles',
                            description: `
                                <p>Los carretes retráctiles “Prevost” fueron diseñados para lograr una buena organización en el área de trabajo.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Pueden ser colocados en diversas superficies. </li>
                                    <li>Son prácticos. </li>
                                    <li>De fácil y rápida instalación.</li>
                                    <li>Placas de montaje que no requieren perforación de superficies.</li>
                                    <li>Carretes cerrados o abiertos.</li>
                                    <li>Sistema retráctil.</li>
                                    <li>Diseño de carcasa protectora que ofrece alta resistencia a la corrosión.</li>
                                </ul>
                            `
                        },
                        4: {//Conectores rápidos anti-latigazo
                            id: 'conectores-rapidos-anti-latigazo',
                            title: 'Conectores rápidos anti-latigazo',
                            description: `
                                <p>Mediante una sola pulsación, este tipo de conectores “Prevost” pueden liberar la presión interna y retirarlos inmediatamente con total seguridad.</p>
                            `,
                            features: `
                                <ul>
                                    <li>Se cuenta con una gama de colores para diferenciar áreas de trabajo o condiciones de operación.</li>
                                    <li>Tecnología patentada.</li>
                                    <li>Diseño ergonomico.</li>
                                    <li>Válvula aerodinámica que favorece el caudal de aire.</li>
                                    <li>3 años de garantía contra defectos de fabricación.</li>
                                </ul>
                            `
                        }
                    }
                }
            },
        },
    },
    services:{//listo
        button: 'CONTÁCTANOS',
        0: {
            title: 'Renta',
            body: `
                <p>La renta de equipos incluye: soporte técnico y mano de obra calificada en todos sus equipos.</p>
                <p>Nuestros equipos incluyen: Compresores estacionarios con inyección de aceite, Compresores libres de aceite, Bombas de vacío, Secadores industriales.</p>
            `
        },
        1: {
            title: 'Mantenimiento',
            body: `
                <p>Contamos con servicio de mantenimiento de carácter preventivo, correctivo y predictivo en todas las marcas que representamos.</p>
                <p>Mantenimiento preventivo corresponde a un mantenimiento sistemático, en el que se interviene a los equipos según las recomendaciones del fabricante. </p>
                <p>El mantenimiento correctivo se realiza al momento de recibir los reportes de alguna falla o defecto que va presentando un equipo.</p>
                <p>Mantenimiento predictivo se basa en un análisis de las instalaciones, el cual aporta la información necesaria para pronosticar la futura falla de un componente en un equipo.</p>
            `
        },
        2: {
            title: 'Pólizas',
            body: `
                <p>Al contratar una póliza de mantenimiento obtienes visitas de acuerdo con las necesidades de la planta; atención telefónica 24/7; mano de obra por técnicos capacitados; refacciones especificadas en el programa de mantenimiento para servicios preventivos; precio congelado de las refacciones y la MO; reporte del estado físico del equipo; entre otros servicios.</p>
            `
        },
        3: {
            title: 'Refacciones',
            body: `
                <p>Maqpower cuenta con el almacén más completo en refacciones para compresores en la República Mexicana. Al ser distribuidor autorizado directo de fábrica, brinda el soporte total en refacciones “Sullair” 100% originales, con garantías de 90 días en refacciones contra defectos de fabricación.</p>
            `
        },
        4: {
            title: 'MAQ (Monitoring Air Quality)',
            body: `
                <p>Obtén un estudio completo del proceso de generación y distribución del sistema de aire comprimido, para así detectar oportunidades y posibles ahorros energéticos que mejoren la calidad de aire y logren el nivel de cumplimiento de ISO 8573-1.</p>
            `,
        },
        5: {
            title: 'Medición Energética',
            body: `
                <p>La medición energética conlleva una revisión de todo el sistema, desde el lado del suministro y distribución, hasta el punto de uso.</p>
                <p>Se utiliza el registro de datos para documentar el desempeño del sistema, y así identificar claramente las oportunidades de mejora para proveer recomendaciones imparciales.</p>
                <p>Los reportes se basan en los estudios presentados por el Compressed Air and Gas Institute (CAGI), que es el organismo que regula toda la información de ingeniería de los fabricantes de sistemas de aire comprimido.</p>
                <p>Una vez obtenida la información base, y con ayuda del estudio realizado, se puede determinar el cómo el compresor puede adaptarse a las necesidades de la planta, conociendo el control de capacidad que éste utiliza.</p>
                <p>MAQPOWER cuenta con el único agente en México con certificación del US Department of Energy y el Compressed Air Challenge como: AIR MÁSTER + SPECIALIST</p>
            `
        },
        6: {
            title: 'Cursos',
            body: `
                <p>Nuestros cursos están dirigidos a clientes y usuarios, así como a ingenieros de procesos, operaciones, técnicos de mantenimiento y proyectistas que desarrollan tareas de selección, instalación, mantenimiento e inspección de compresores industriales.</p>
                <p><span>Cursos In Company.</span></p>
                <p><span>Cursos Prácticos.</span></p>
                <p><span>Cursos virtuales.</span></p>
                <p>Con duración promedio de 8 hrs, y con posibilidad de avalar el curso con diploma de asistencia o DC-3</p>
            `
        },
        7: {
            title: 'Reparación de unidades de compresión',
            body: `
                <p>Es importante contar con un diagnóstico oportuno hecho por personal calificado. Este puede ser incluido y aplicado durante el servicio de mantenimiento predictivo, el cual se realiza cada 8,000 hrs o 1 año, de acuerdo con lo que pase primero.</p>
                <p>Gracias a este diagnóstico, se puede llevar un control y detectar con mayor facilidad algún ruido anormal en la unidad compresora, además de identificar partículas metálicas u algún otro contaminante en el resultado de la muestra de aceite.</p>
            `
        }
    },
    engineering:{
        eng: {
            0: {
                title: 'Asesoría En Ingeniería',
                body: `
                    <p>Con Ingenieros altamente capacitados podemos asesorarte para que tu sistema de aire comprimido funcione en su forma más optima. Si tu necesidad actual es desarrollar un nuevo sistema de aire comprimido te acompañamos a lo largo de tu proyecto, desde la selección del equipo necesario para cubrir tus necesidades hasta hacerlo llave en mano.</p>
                `
            },
            1: {
                title: 'Reparación de unidades de compresión',
                body: `<p>Gracias a este diagnóstico, se puede llevar un control y detectar con mayor facilidad algún ruido anormal en la unidad compresora, además de identificar partículas metálicas u algún otro contaminante en el resultado de la muestra de aceite.</p>`
            },
            2: {
                title: 'Reparación de unidades de compresión',
                body: `<p>En caso de que el cliente opte por un diagnóstico adicional durante el mantenimiento se aplicará un cambio de baleros a la unidad de compresión cuando los rangos de vibración estén fuera de los parámetros normales.</p>`
            },
            3: {
                title: 'Reparación de unidades de compresión',
                body: `<p>El diagnóstico, junto con el mantenimiento preventivo en la unidad, prolongarán el tiempo de vida de la máquina, y ayudarán a que trabaje en óptimas condiciones.</p>`
            },
            4: {
                title: 'Reparación de unidades de compresión',
                body: `<p>Mantenemos las mismas tolerancias que el fabricante para asegurar la vida útil y la eficiencia de la unidad de compresión.</p>`
            },
        },
        after:{
            title: 'MAQ (Monitoring Air Quality)',
            body: `
                <p>Con este servicio obtén un estudio completo del proceso de generación y distribución del sistema de aire comprimido, para así detectar oportunidades y posibles ahorros energéticos.</p>
                <p>Monitoring: conoce el consumo actual del sistema de aire comprimido durante un periodo de tiempo.</p>
                <p>AIR: La cantidad de aire generada debe ser la adecuada para cumplir con las necesidades de su infraestructura, controla el flujo y las fugas de aire.</p>
                <p>Quality: obtén un reporte completo de los parámetros del aire comprimido en tiempo real.</p>
                <p>En conclusión, documenta e interpreta parámetros para poder identificar recomendaciones que mejoren la calidad y logren el nivel de cumplimiento de ISO 8573-1.</p>
            `,
            button: 'CONTÁCTANOS',
        }
    },
    blog:{//listo (falta checar los botones)
        title: 'Blogs',
        button: 'LEER MÁS'
    },
    thankyou:{ //listo
        title: 'Brindando soluciones para la industria desde 1981',
    },
    thankyourh:{ //listo
        title: '¡Gracias por tu interés en colaborar con nosotros!',
        description: 'Hemos recibido tu información. La revisaremos y nos pondremos en contacto contigo a la brevedad posible. '
    },
    privacy: {
        title: 'Aviso de privacidad integral',
        body: `
            <p>Compresores Maqpower S.A. de C.V., con domicilio en Av. Aviación #5051 Int-21, Colonia San Juan Ocotán, C.P.45019, Zapopan, Jalisco; y portal de internet <a target="blank" rel="noopener" title="Website" href="https:\\www.maqpower.com.mx">www.maqpower.com.mx</a>, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:</p>
            <h3>¿Para qué fines utilizaremos sus datos personales?</h3>
            <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:</p>
            <p>Respuesta a mensajes del formulario de contacto, y prestación de cualquier servicio solicitado.</p>
            <h3>¿Qué datos personales utilizaremos para estos fines?</h3>
            <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
            <p>Datos de identificación y contacto, datos laborales, y datos académicos.</p>
            <h3>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso o ejercer la revocación de consentimiento?</h3>
            <p>Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</p>
            <p>Para el ejercicio de cualquiera de los derechos ARCO, debe enviar una petición vía correo electrónico a <a href="mailto:mercadotecnia@maqpower.com.mx" target="blank" rel="noopener" title="Mercadotecnia" >mercadotecnia@maqpower.com.mx</a> y deberá contener:</p>
            <p>Nombre completo del titular.</p>
            <p>Domicilio.</p>
            <p>Teléfono.</p>
            <p>Correo electrónico usado en este sitio web.</p>
            <p>Copia de una identificación oficial adjunta.</p>
            <p>Asunto «Derechos ARCO».</p>
            <p>Descripción de los objetos del escrito, los cuales pueden ser de manera enunciativa más no limitativa los siguientes: Revocación del consentimiento para tratar sus datos personales; y/o Notificación del uso indebido del tratamiento de sus datos personales; y/o Ejercitar sus Derechos ARCO, con una descripción clara y precisa de los datos a Acceder, Rectificar, Cancelar o bien, Oponerse. En caso de Rectificación de datos personales, deberá indicar la modificación exacta y anexar la documentación soporte; es importante en caso de revocación del consentimiento, que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.</p>
            <h3>¿En cuántos días le daremos respuesta a su solicitud?</h3>
            <p>10 días</p>
            <h3>¿Por qué medio le comunicaremos la respuesta a su solicitud?</h3>
            <p>Al mismo correo electrónico de donde se envió la petición.</p>
            <p>El uso de tecnologías de rastreo en nuestro portal de internet.</p>
            <p>Le informamos que en nuestra página de internet utilizamos cookies, web beacons u otras tecnologías, a través de las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un mejor servicio y experiencia al navegar en nuestra página. Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:</p>
            <p>Identificadores; nombre de usuario y contraseñas de sesión; idioma preferido por el usuario; región en la que se encuentra el usuario; y publicidad revisada por un usuario.</p>
            <p>Estas cookies, web beacons y otras tecnologías pueden ser deshabilitadas. Para conocer cómo hacerlo, consulte el menú de ayuda de su navegador. Tenga en cuenta que, en caso de desactivar las cookies, es posible que no pueda acceder a ciertas funciones personalizadas en nuestro sitio web.</p>
            <h3>¿Cómo puede conocer los cambios en este aviso de privacidad?</h3>
            <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas. Nos comprometemos a mantener actualizado este aviso de privacidad sobre los cambios que pueda sufrir y siempre podrá consultar las actualizaciones que existan en el sitio web <a href="https:\\www.maqpower.com.mx" target="blank" rel="noopener" title="Website" >www.maqpower.com.mx.</a></p>
            <p>Última actualización de este aviso de privacidad: 20/12/2021</p>
        `
    },
    terms: {
        title: 'Términos y condiciones',
        body: `
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum. Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum. Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum. Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum. Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat totam ab temporibus quae blanditiis. Nihil, id. Unde ex odio exercitationem nesciunt inventore qui. Itaque eos ipsa nam saepe, autem earum.</p>
        `
    },
    rh: {
        description: {
            title: 'Estamos creciendo y buscando al mejor talento.',
            body: `
                <p>En Compresores Maqpower buscamos a aquellos a los que les gusta superar retos; personas talentosas que se enfocan en el detalle, saben priorizar y se apasionan por encontrar soluciones.</p>
            `,
        },
        stats: {
            description: `
                Estamos aquí no solo para brindar las mejores soluciones de aire comprimido para la industria, sino también para agregar valor, generar confianza y estar a la vanguardia en nuestros procesos. Todo ello con personal altamente capacitado, basados en la filosofía de los accionistas y fomentando el crecimiento de energías limpias.
            `,
            0: {
                number: '9',
                title: 'sucursales'
            },
            1: {
                number: '100',
                title: 'empleados'
            },
            2: {
                number: '40+',
                title: 'años sirviendo con integridad'
            },
        },
        contact:{
            form: {
                name: 'Nombre',
                email: 'Correo Electrónico',
                phone: 'Teléfono',
                age: 'Edad',
                scholarship: {
                    title: 'Nivel de Escolaridad',
                    0: 'Primaria',
                    1: 'Secundaria',
                    2: 'Bachillerato',
                    3: 'Universidad',
                    4: 'Postgrado',
                    5: 'Doctorado',
                    6: 'Otro'
                },
                area: {
                    title: 'Área de interés',
                    0: 'Ingeniería',
                    1: 'Contabilidad / Administración',
                    2: 'Mantenimiento',
                    3: 'RRHH',
                    4: 'Comercial',
                    5: 'Almacén',
                    6: 'Atención a Clientes',
                },
                dispo: {
                    title: 'Disponibilidad',
                    0: 'Medio tiempo',
                    1: 'Tiempo completo',
                    2: 'Practicante',
                    3: 'Freelance',
                },
                exp: 'Experiencia',
                hability: 'Habilidades',
                submit: 'Enviar'
            },
            file: {
                selected: 'Seleccionar archivo',
                type: 'Adjuntar CV en formato .pdf, .doc o .docx',
                status: 'No se eligió archivo',
            }
        }
    },
    footer:{
        copy: 'Maqpower 2023. Todos los derechos reservados',
        job: 'Bolsa de trabajo',
        notice: 'Aviso de privacidad',
        terms: 'Términos y condiciones',
        certification: {
            text: 'En proceso de certificación'
        }
    }
}