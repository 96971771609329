import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home')
  },
  {
    path: '/about-us',
    name: 'About',
    component: () => import('../views/about')
  },
  {
    path: '/products',
    name: 'Productos',
    component: () => import('../views/products')
  },
  {
    path: '/products/category/:id',
    name: 'Categoria de productos',
    component: () => import('../views/products')
  },
  {
    path: '/products/product/:id',
    name: 'Producto',
    component: () => import('../views/product')
  },
  {
    path: '/services',
    name: 'Servicios',
    component: () => import('../views/services')
  },
  {
    path: '/engineering',
    name: 'Ingenieria',
    component: () => import('../views/engineering')
  },
  {
    path: '/thank-you',
    name: 'Thankyou',
    component: () => import('../views/thankyou')
  },
  {
    path: '/thank-you-rh',
    name: 'Thankyou-RH',
    component: () => import('../views/thankyourh')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/privacy')
  },
  {
    path: '/rh',
    name: 'rh',
    component: () => import('../views/rh')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition)
      return savedPosition;
    else
      return {x: 0, y: 0};
  }
})

export default router
